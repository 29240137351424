@import "../../../../scss/trashie";

.UserBalance {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 239px;
        height: 100%;
        max-width: 320px;
        width: 100%;
        padding: 62px 62px 62px 62px;
        gap: 16px;
        border-radius: 8px;
        background: var(--trashie-ui-crisp-white);
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
        word-wrap: break-word;
        overflow-wrap: break-word;

        &--title {
            font-family: GT Maru;
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            text-align: center;
            text-transform: none;
            width: 173px;
        }

        &--balance {
            display: flex;
            flex-direction: row;
            font-family: GT Maru;
            align-items: center;
            font-size: 36px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
        }

        &--link {
            font-family: GT Maru;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-decoration: underline;
            text-align: center;
        }

        &--icon {
            height: 36px;
            padding: 0 0 2px 4px;
        }
    }
}

@media screen and (max-width: 1020px) {
    .UserBalance {
        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            background: none;
            max-width: 100%;
            padding: 0px;
            box-shadow: none;
    
            &--title {
                font-family: GT Maru;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                text-align: left;
                text-transform: none;
            }
    
            &--balance {
                font-family: GT Maru;
                font-size: 36px;
                font-weight: 500;
                line-height: 36px;
                text-align: left;
            }
    
            &--link {
                font-family: GT Maru;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                text-align: left;
                text-decoration: underline;
            }
        }
    }
}