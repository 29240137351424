$primary: #FCFAEE;
$secondary: #F3F2EA;
$success: #CEF9FF;
$info: #FFC3BA;
$dark: #231f20;
$warning: #e4ab26;
$danger:#FF674C;
$red: #f94c2c;
$light: #FFF;
$text-muted:#dddddda3;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "muted": $text-muted,
  "red": $red
);

$border-radius: 0;
$input-border-color: $dark;
$font-family-base: "basiccommercial", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$link-color: $dark;
$input-btn-padding-y: .75rem;
$input-btn-padding-y-sm: .5rem;
$component-active-color: $dark;
$component-active-bg: $secondary;
$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius;
$input-border-radius-sm: $border-radius;
$h1-font-size: 40px;
$h2-font-size: 32px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$small-font-size: 12px;
$display1-size: 100px;
$display2-size: 64px;
$headings-font-weight: 400;
$headings-line-height: 1.25;
$btn-border-radius: 30px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$card-spacer-y: 1rem;
$card-spacer-x: 2rem;
$card-border-color: $dark;
$card-cap-bg: $light;
$card-cap-color: $dark;
$card-color: $dark;
$input-btn-padding-y: .75rem;
$input-padding-y: $input-btn-padding-y;
$btn-padding-y: $input-btn-padding-y;
$card-border-radius: $border-radius;
$modal-content-border-radius: 0;
$modal-content-inner-border-radius: 0;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    6: (
      $spacer * 3,
    ),
    7: (
      $spacer * 3.5,
    ),
    //All new ones
    8: (
      $spacer * .5,
    ),
    16: (
      $spacer,
    ),
    24: (
      $spacer * 1.5,
    ),
    32: (
      $spacer * 2,
    ),
    40: (
      $spacer * 2.5,
    ),
    48: (
      $spacer * 3,
    ),
    56: (
      $spacer * 3.5,
    ),
    64: (
      $spacer * 4,
    ),
    72: (
      $spacer * 4.5,
    ),
    80: (
      $spacer * 5,
    ),
    88: (
      $spacer * 5.5,
    ),
    96: (
      $spacer * 6,
    ),
    120: (
      $spacer * 7.5,
    ),
    128: (
      $spacer * 8,
    ),
    136: (
      $spacer * 8.5,
    ),
    144: (
      $spacer * 9,
    ),
    152: (
      $spacer * 9.5,
    ),
    168: (
      $spacer * 10.5,
    ),
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  ) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
  xxl: 1400px,
);

$display1-size: 115px;
$display2-size: 75px;

$display-font-sizes: (
  1: $display1-size,
  2: $display2-size,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
);