.RewardFlag {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 0 0 0 14px;
    padding: 4px 12px 4px 8px;
    border: 0;
    background: var(--ui-crisp-white);
    color: var(--trashie-ui-faded-black-100);
    font-size: 12px;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    z-index: 1;

    p {
        margin: 0;
    }

    img {
        height: 20px;
    }
}
