@import '../../../../../scss/_trashie.scss';

.RewardCover {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 14px;
    background: var(--trashie-ui-crisp-white);
    position: relative;
    overflow: hidden;

    &__imgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px 14px 0 0;
        background: none;
        width: 100%;
        height: 248px;
        overflow: hidden;

        img {
            min-width: 100%;
            min-height: 100%;

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    &__card {
        display: flex;
    }

    &__code__container {
        display: flex;
    }

    &.list {
        border: 0.5px solid var(--trashie-ui-faded-black-30);
        width: 236px;
        height: 364px;

        .RewardCover__imgContainer {
            height: 248px;

            img {
                object-fit: cover;
            }
        }

        .RewardCover__card {
            width: 204px;
            height: 120px;
            margin: -20px auto 0;
        }
    }

    &.redeem,
    &.final {
        width: 100%;
        height: auto;

        .RewardCover__imgContainer {
            height: 300px;

            img {
                object-fit: cover;
            }
        }
        .RewardCover__imgContainer--disabled {
            height: 150px;

            img {
                object-fit: cover;
            }
        }
        .RewardCover__card {
            width: 320px;
            height: 190px;
            margin: -70px auto 0;
        }
    }
}

// SIZE_XS
@media screen and (max-width: 576px) {
    .RewardCover {
        &.list {
            width: 100%;

            .RewardCover__card {
                width: 85%;
                height: 200px;
                margin: -20px auto 20px auto;
            }
        }
    }
}
