@import '../../../../scss/_trashie.scss';
.main {
    display: flex;
    width: 100%;
    padding: 64px;
    background: var(--trashie-ui-faded-black-10);
    .container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;
        gap: 32px;
        .listContainer{
            max-width: 800px;
            width: 100%;
        }
    }
    .titleContainer{ 
        width: 800px;
    }
    .buttonContainer{
        max-width: 260px;
    }

}

@media screen and (max-width: 1020px) {
    .main{
        padding: 32px;
        .container {
            .listContainer{
                width: 100%;
            }
        }
        .titleContainer{
            max-width: 335px;
        }
        .buttonContainer{
            width: 72px;
        }
        .buttonText{
            display: none;
        }
    }
}