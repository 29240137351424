@import '../../../../scss/_trashie.scss';

.main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-transform: uppercase;
    font-family: Gt Maru, sans-serif;
    font-weight: 500;
    background-color: var(--trashie-ui-faded-black-10);

    .content {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: 72px;

        .loader {
            display: flex;
            flex: 1;
            align-items: center;

            @include animateFadeIn;
        }
    }
}

.learnMore {
    @include ctaLink;
    @include ctaMedium;

    margin-left: auto;
}

.arrow {
    margin-left: auto;
    border: 0.5px solid var(--trashie-ui-faded-black-100);
    border-radius: 50%;
    padding: 4px;
    height: 20px;
    margin-left: 16px;
    background-color: unset;
    transform: rotate(270deg);
}

.button {
    @include ctaPrimary;
    @include ctaMedium;

    width: fit-content;
    margin-left: 8px;
}

@media screen and (max-width: 1020px) {
    .main {
        .content {
            padding-top: 63px;
        }

        .learnMore {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
        }
    }
}
