.OptIn {
    width: 100%;

    &__label {
        color: var(--trashie-ui-faded-black-100) !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 150% !important;
    }
}
