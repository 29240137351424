@import "../../../../scss/trashie";

.EarnRewardBanner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    width: 100%;
    height: 239px;
    padding: 80px 64px;
    align-items: center;
    cursor: pointer;
    background: var(--trashie-ui-crisp-white) url('../../images/TBB-bags-desktop.png') no-repeat calc(100% - 65px) bottom / 258px;


    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding: 16px 0px 16px 0px;
        text-align: left;
        text-transform: none;

        &--title {
            color: #000;
            font-family: "GT Maru";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }

        &--description {
            color: var(--trashie-ui-faded-black-80);
            font-family: "GT Maru";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            max-width: 440px;
            width: 100%;
            align-self: stretch;
        }
    }

    &__image {
        img {
            margin-top: 19px;
            max-height: 220px;
            max-width: 258px;
        }
    }

}

@media screen and (max-width: 768px) {
    .EarnRewardBanner {
        display: flex;
        flex-direction: row;
        padding: 0px 0px 0px 16px;
        max-height:108px;
        gap: 12px;
        background-size: 128px;
        background-position: right;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            padding: 16px 0px 16px 0px;
            text-align: left;
            text-transform: none;
            max-width: 218px;
            width: 100%;

            &--title {
                color: #000;
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }

            &--description {
                color: var(--trashie-ui-faded-black-80);
                font-family: "GT Maru";
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1020px) {
    .EarnRewardBanner {
        padding: 48px 64px;  
        max-height: 162px;
        background-size: 320px;
        background-position: calc(100% - 64px) calc(100% + 75px);

        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            padding: 16px 0px 16px 0px;
            text-align: left;
            text-transform: none;
            max-width: 300px;
            width: 100%;

            &--title {
                color: #000;
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
            }

            &--description {
                color: var(--trashie-ui-faded-black-80);
                font-family: "GT Maru";
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                max-width: 355px;
            }
        }
    }
}
