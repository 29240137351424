.noDataContainer{
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-family: GT Maru;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
}
.noDataButton{
    width: 320px;
}
.noDataImage{
    width: 800px;
}

@media screen and (max-width: 1020px) {
    
        .noDataContainer{
            max-width: 335px;
        }

        .noDataImage{
            max-height: 148px;
            max-width: 327px;
        }
        
}