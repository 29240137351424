.NavigationItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    &__icon {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
    }

    &__label {
        color: #000;
        text-align: center;
        font-family: "GT Maru";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-transform: none;
    }
}

@media screen and (max-width: 1020px) {
    .NavigationItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        max-width: 75px;
        width: 100%;
    
        &__icon {
            display: flex;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            background: var(--trashie-ui-crisp-white);
            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
        }
    
        &__label {
            color: #000;
            text-align: center;
            font-family: "GT Maru";
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            text-transform: none;
        }
    }
}