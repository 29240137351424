@import '../../../../scss/trashie';

.MarketingInfo {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;

  img {
    width: inherit;
    max-width: 960px;
    max-height: 720px;
  }
}

@media screen and (max-width: 1440px) {
  .MarketingInfo {
    img {
      max-width: 800px;
      max-height: 600px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .MarketingInfo {
    padding: 0;

    img {
      width: auto;
      max-height: 258px;
      max-width: unset;
    }
  }
}
