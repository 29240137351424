.debug * {
  outline: 1px solid limegreen;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    .border-#{$breakpoint}-0 {
      border: none !important;
    }
  }
}

$fontWeights: (100, 200, 300, 400, 500, 600, 700, 800);
@each $weight in $fontWeights {
  .font-#{$weight} {
    font-weight: $weight !important;
  }
}

@font-face {
  font-family: "Knockout";
  src: local("Knockout"), url(./fonts/knockout.woff) format("woff");
}

@font-face {
  font-family: 'A2RecordGothicXCondensed';
  src: url(./fonts/A2RecordGothicXCondensed-Black.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'A2RecordGothicCondensed';
  src: url(./fonts/A2RecordGothicCondensed-Bold.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'GT-America';
  src: url(./fonts/GT-America.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'BasicCommercialLTStd-Lt';
  src: url(./fonts/BasicCommercialLTStd-Lt.otf) format('opentype');
  font-display: swap;
}

$fontSizes: (12, 14, 16, 18, 24, 30, 40, 48, 50, 80, 60, 100, 120, 140, 150, 200);

@each $size in $fontSizes {
  .font-size-#{$size} {
    font-size: #{$size}px !important;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size in $fontSizes {
    @include media-breakpoint-up($breakpoint) {
      .font-size-#{$breakpoint}-#{$size} {
        font-size: #{$size}px !important;
      }
    }
  }
}

.closetImageWrapper {
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.closetImageContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.closetImage {
  max-width: 100%;
  max-height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.redeem-bg {
  background-image: url("../images/redeem/bg-image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.rounded-75 {
  border-top-right-radius: 75px;
  border-top-left-radius: 75px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.2);
}

.circle {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: none;
}

.circle-background {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: none;
  position: relative;
  top: -37.5px;
}

.circle-shadow {
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1;
}

.circle-content {
  position: absolute;
  z-index: 3;
}

.circle-cover {
  width: 100px;
  height: 40px;
  position: absolute;
  z-index: 2;
  margin-top: 40px;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.w-40 {
  width: 40%;
}

.font-10 {
  font-size: 10px;
}

.pill-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.menu-closed,
.menu-opened {
  width: 90%;
  position: absolute;
  z-index: 9999;
  transition: right 0.4s ease-out;
  top: 0;
  box-shadow: -5px 0px 18px rgba(0, 0, 0, 0.25);
}

@include media-breakpoint-up(md) {
  .menu-closed,
  .menu-opened {
    width: 30% !important;
  }
}

.menu-opened {
  right: 0;
}

.menu-closed {
  right: -100%;
}

.no-bullets {
  list-style: none;
}

.scrollable-content {
  overflow-y: auto;
}

.selectable {
  cursor: pointer;
}

.closet-card {
  height: 275px;
  display: flex;
  flex-direction: column;
  background-color: rgb(221 221 221 / 15%);
  &:hover {
    filter: brightness(0.8);
  }
  &.active {
    background-color: rgb(237 169 0 / 35%);
  }
  &--footer {
    background-color: rgb(221 221 221 / 50%);
    padding: $spacer * 0.6;
    margin-top: auto;
  }
  @include media-breakpoint-up(md) {
    height: 400px;
  }
}

.custom-datepicker {
  .react-datepicker__input-container {
    width: auto;
    display: flex;
  }
  .react-datepicker__close-icon {
    position: static;
  }
  .react-datepicker__close-icon::after {
    background: transparent;
    @extend .link-dark;
    font-size: 20px;
  }
}

.modal-background {
  background: rgb(0 0 0 / 50%);
}

.container-content-xxs {
  max-width: 220px;
}

.container-content-xs {
  max-width: 400px;
}

.container-content-sm {
  max-width: 550px;
}

.container-content-md {
  max-width: 850px;
}

.container-content-lg {
  max-width: 1180px;
}

.container-content-xl {
  max-width: 1440px;
}

body {
  background-color: $primary;
}

input::placeholder {
  color: #333 !important;
}

.font-knockout {
  font-family: "Knockout" !important;
}

.hero {
  font-family: "Knockout" !important;
  font-size: 135px;
  line-height: 0.9;
}

.text-brand-gold {
  color: #e4ab26;
}

.custom-padding-under-290 {
  padding-left: 2rem;
  padding-right: 2rem;
  @media only screen and (max-width: 290px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  @media only screen and (min-width: 992px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

#heavy-lifting-section {
  background: url("../images/three-quarters-recycle.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-color: #808080;
}

#recycle-images-section {
  background: url("../images/white-shirt-woman.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  background-position: center;
}

#left-to-right {
  text-align: center;
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);

  -moz-animation: my-animation 25s linear infinite;
  -webkit-animation: my-animation 25s linear infinite;
  animation: my-animation 25s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.hide-border-right-medium {
  @include media-breakpoint-down(md) {
    border-right: 0 !important;
  }
}

.hide-border-right-small {
  @include media-breakpoint-down(sm) {
    border-right: 0 !important;
  }
}

#hide-bg-image {
  @include media-breakpoint-down(md) {
    background-image: none !important;
  }
}

.fs-square {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1em;
  width: 1em;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 0.75em solid transparent;
  border-right: 0.75em solid transparent;
  border-bottom: 1em solid var(--bs-dark);
  position: relative;
  &::after {
    content: "";
    border-left: 0.7em solid transparent;
    border-right: 0.7em solid transparent;
    border-bottom: 0.95em solid var(--bs-primary);
    position: absolute;
    left: -0.7em;
    top: 0.1em;
  }
}

.CircularProgressbar-path {
  stroke: $warning;
}

.CircularProgressbar-trail {
  stroke: #ddd;
}

// New Colors
$cc-black: #363436;
$cc-green: #085a4c;
$cc-white-green: #f5ffe0;
$cc-digital-green: #ffffff;
$cc-newsprint: #efeeed;

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  align-items: center;
}

.flex-between {
  justify-content: space-between;
}

.justify-between {
  justify-content: space-between;
}

.bg-cc-green {
  background: $cc-green;
}

.bg-cc-white-green {
  background: $cc-white-green;
}

.text-cc-black {
  color: $cc-black;
}

.text-white-green {
  color: $cc-white-green;
}

.text-cc-newsprint {
  color: $cc-newsprint;
}

.text-bold {
  font-weight: 700;
}

.text-upper {
  text-transform: uppercase;
}

.text-link {
  text-decoration: none;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.rounded-3_5 {
  border-radius: 10px !important;
}

.shadow-google-btn {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}

.font-small {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.EmbedLogin {
  overflow: hidden;

  small {
    font-size: 8px;
    line-height: 10px;
  }

  .custom-input {
    padding: 12.5px !important;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  .custom-button {
    padding: 12.5px !important;
    background: $cc-white-green !important;
    color: $cc-black !important;
    border: none;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    &:disabled {
      opacity: 1;
    }
  }

  .custom-button--dark {
    padding: 12.5px !important;
    background: $cc-black !important;
    color: $cc-digital-green !important;
    border: none;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    &:disabled {
      opacity: 1;
    }
  }

  .custom-button--google {
    margin-bottom: 7px !important;
    padding: 10px 12px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.54);
  }

  .custom-button--facebook {
    margin-bottom: 7px !important;
    padding: 10px 12px !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  .custom-button--small {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
  }
}

$paddingSizes: (0.25, 0.5, 1);
@each $size in $paddingSizes {
  $sizeStr: #{$size};

  .pr-#{str-replace($sizeStr, ".", "_")} {
    padding-right: #{$size}rem !important;
  }

  .pl-#{str-replace($sizeStr, ".", "_")} {
    padding-left: #{$size}rem !important;
  }
}
