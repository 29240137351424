@import '../../../../scss/_trashie.scss';

.main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;
    background: var(--trashie-ui-faded-black-10);

    .container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        max-width: 1312px;

        font-family: GT Maru;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        padding: 112px 0;
    }

    .noData {
        color: var(--ui-faded-black);
        text-overflow: ellipsis;
        font-family: GT Maru;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 118.6%;
        text-transform: uppercase;
        align-self: center;
        margin-top: 60px;
    }

    .loader {
        align-self: center;
    }

    .subTitleExtraStyle{
        max-width: 500px;
        overflow-wrap: break-word;
    }

    .cta {
        text-decoration: underline;
    }
}

@media screen and (max-width: 1020px) {
    .main {
        padding: 0;

        .container {
            padding: 64px 20px;

            .titleSection {
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-bottom: 0;

                .contentText {
                    width: 80%;
                    align-items: center;

                    .subtitle {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
