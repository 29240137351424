@import '../../../../scss/trashie';

.RewardInfo{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 350px;
    background: var(--trashie-ui-faded-black-10);

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 800px;
        padding: 64px 16px;

        &--container {
            width: 100%;
        }

        &--loader {
            margin: 80px auto;
        }

        &--button {
            width: fit-content;
        }

        &--section{
            margin-top: 16px;
            background: var(--ui-crisp-white);
            border-radius: 20px;
            overflow: hidden;
        }

    }
    &__ctaContainer{
        display: none;
    }
}

@media screen and (max-width: 1020px) {
    .RewardInfo {
        &__content {
            padding: 32px 16px;
        }
        &__ctaContainer{
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: center;
            padding: 16px 32px; 
            border-top: 1px solid var(--trashie-ui-faded-black-100);
            border-bottom: 1px solid var(--trashie-ui-faded-black-100);
            background: var(--trashie-ui-crisp-white);
            position: sticky;
            bottom: 0;
            width: 100%;
            z-index: 1;
            .cta-shop {
            @include ctaLarge;
            @include ctaPrimary;
            max-width: 326px;
            }
        }
    }
}