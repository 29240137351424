.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes shine {
  to {
    left: -200%
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.redemption-form-labels {
  color: var(--key-line-grey, #A6A6A6);
  font-family: GT-America;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 1rem !important;
}

@media only screen and (max-width:480px) {
  .redemption-form-labels {
    margin-top: 1rem !important;
  }
}

.cs-pin-inputs {
  text-align: center;
  border-radius: 10px !important;
  margin-right: 1% !important;
  margin-left: 1% !important;
  max-width: 12% !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 20px !important;
  font-family: GT-America !important;
  font-weight: 300 !important;
}

.cs-pin-inputs-wer::placeholder {
  color: red !important;
  opacity: 1;
}

.form-address-modal>.modal-dialog>.modal-content {
  border-radius: 20px;
}

.form-address-modal input {
  width: 100% !important;
  border-radius: 10px;
  border: 0.5px solid #A6A6A6;
  background: #FFF;
}

.form-address-modal .form-control:focus {
  color: #212529;
  border-color: #000 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(243, 242, 234, 0.25);
}

.form-address-modal .form-select {
  background-image: url("../public/images/select-icon.svg") !important;
  background-size: 25px 13px !important;
  background-position: right 20px center !important;
  border-radius: 10px !important;
  border: 0.5px solid #A6A6A6 !important;
  color: #A6A6A6 !important;
  font-family: GT-America !important;
  font-weight: 300 !important;
  font-size: 18px !important;
  padding: 10px 16px 10px 16px !important;
  font-style: normal !important;
  line-height: 1.5 !important;
}

.form-address-modal .form-select.active {
  color: #000 !important;
}

.form-address-modal .title {
  color: var(--ui-colors-faded-black, #363436);
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
  margin-bottom: 32px;
  font-family: 'A2RecordGothicCondensed';
}

.form-address-modal .btn-close {
  border-radius: 50px;
  background-color: CC Digital White;
  box-shadow: 0px 0px 3px 2px rgba(54, 52, 54, 0.06);
  color: #231F20;
  opacity: 1;
  background: transparent url("../public/images/close-icon.svg") center/1em auto no-repeat;
  padding: calc(var(--bs-modal-header-padding-y) * 0.8) calc(var(--bs-modal-header-padding-x) * 0.8);
}

.form-address-modal .btn-close:hover {
  color: #231F20;
  opacity: 1;
}

.form-address-modal p {
  color: #A6A6A6;
}

.form-address-modal .sbm-btn {
  width: 100% !important;
  margin-top: 32px !important;
  border-radius: 70px !important;
  background: #363436 !important;
  font-family: 'GT-America' !important;
  color: #FEFEFE !important;
  text-align: center !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 90% !important;
  min-height: 55px !important;
}

button:hover {
  animation: shine .75s cubic-bezier(.01, .56, 1, 1) !important;
}


.remp-land-tabs {
  padding:0;
  margin-right: 0 !important;
}

.pin-fields {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 10px !important;
  max-width: 100% !important;
}

.pin-fields input::placeholder {
  opacity: 0.5 !important;
}

.style-modal-mobile {
  width: 100% !important;
  flex-flow: row !important;
}

.style-city-state-title {
  display: none !important;
}

.style-city-state-title-nd {
  display: block !important;
}

@media only screen and (max-width:480px) {
  .pin-fields {
    max-width: 100% !important;
    margin-bottom: 48px !important;
  }

  .style-modal-mobile {
    width: 100% !important;
    flex-flow: column !important;
  }

  .style-modal-mobile>div {
    width: 100% !important;
    flex-flow: column !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-bottom: 20px;
  }

  .style-city-state-title {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .style-city-state-title-nd {
    display: none !important;
  }
}

.remp-land-tabs .card {
  max-width: 518px !important;
  background-color: #F2F2F2 !important;
  border: 1px solid #000 !important;
  border-radius: 24px !important;
  padding: 16px !important;
  margin: 0 auto;
}

@media only screen and (max-width:480px) {
  .remp-land-tabs {
    margin: auto !important;
    padding: 0;
    .card {
      border: 1px solid #000 !important;
    }
  }
}

.remp-land-tabs .tab-content {
  padding: 0 !important;
}

.remp-land-tabs.row {
  margin: 0 !important;
}

.remp-land-tabs .nav-tabs {
  display: none;
}

.cs-button-submit {
  font-family: GT-America !important;
  font-size: 22px !important;
  font-weight: 300 !important;
  margin-top: 64px !important;
  min-height: 55px !important;
}

p.address-container {
  border-radius: 11px !important;
  border: 1px solid var(--ui-faded-black); 
  padding: 16px !important;
  color: var(--ui-faded-black); 
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 0px !important;
  flex-flow: row !important;
  font-family: GT-America !important;
  font-size: 20px !important;
  font-weight: 300 !important;
}

@media only screen and (max-width:480px) {
  .cs-button-submit {
    font-size: 18px !important;
  }

  p.address-container {
    flex-flow: row !important;
  }
}