@import '../../../../../../scss/trashie';

.RegisterWelcome {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    gap: 32px;
    width: 100%;
    padding: 0 32px 80px;
    margin: auto 0 0;

    &__image {
        display: flex;
        width: 290px;
        align-items: center;
        justify-content: center;
        margin: 32px 0;

        img {
            width: 100%;
            transform: rotate(-15deg);
        }
    }

    &__logo {
        display: flex;
        max-width: 90%;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }

        &.is-trashie {
            width: 180px
        }
    }

    &__text{
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        white-space: pre-line;
    }
}

@media only screen and (max-width: 480px) {
    .RegisterWelcome {
        padding: 0 16px 80px;

        &__image {
            img {
                width: 80%;
            }
        }
    }
}