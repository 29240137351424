@import '../../../../../../scss/trashie';

.SelectShippingProviderView {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &__items {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;
    }
}
