@import '../../../../scss/_trashie.scss';

.btnDefault {
    width: 100%;
}

.primary {
    @include ctaPrimary;
    @extend .btnDefault;
}

.secondary {
    @include ctaSecondary;
    @extend .btnDefault;
}

.tertiary {
    @include ctaTertiary;
    @extend .btnDefault;
}

.quaternary {
    @include ctaQuaternary;
    @extend .btnDefault;
}

.quinary {
    @include ctaQuinary;
    @extend .btnDefault;
}

.link {
    @include ctaLink;
    @extend .btnDefault;
}

.link-quaternary {
    @include ctaLink;
    @extend .btnDefault;

    color: var(--trashie-primary-code-blue);
    text-decoration: none;

    &.disabled {
        text-decoration: none;
    }
}

.link-quinary {
    @include ctaLink;
    @extend .btnDefault;

    color: var(--trashie-primary-code-blue);
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &.disabled {
        text-decoration: none;
    }
}

.small {
    @include ctaSmall;
}

.medium {
    @include ctaMedium;
}

.large {
    @include ctaLarge;
}

.disabled {
    @include ctaDisabled;
}
