:root {
  --ui-faded-black: #1B1A1B;
  --ui-faded-black-60: #696969;
  --trashie-ui-faded-black-10: #F9F9FA;
  --trashie-ui-faded-black-20: #E6E6E6;
  --trashie-ui-faded-black-30: #D9D9D9;
  --trashie-ui-faded-black-40: #A9A9A9; 
  --trashie-ui-faded-black-50: #8D8D8D;
  --trashie-ui-faded-black-60: #6F706F;
  --trashie-ui-faded-black-70: #696969;
  --trashie-ui-faded-black-80: #545354;
  --trashie-ui-faded-black-100: #1B1A1B;
  --trashie-ui-faded-black-100-b: #292829;
  --ui-crisp-white: #FEFEFE;
  --japan-color-hard-mint: #7DF8A4;
  --japan-color-confection-lemon: #FFFF73;
  --japan-color-dry-red: #E13922;
  --japan-color-flat-sky: #BBD8FB;
  --ui-key-line-gray: #A9A9A9;
  --ui-wow-light-gray: #E2E2E2;
  --japan-color-sky-boi: #2B66F6;
  --trashie-primary-thrifty-blue: #9AD2FF;
  --trashie-ui-thrifty-sky: #EBF5FF;
  --trashie-primary-berlin-yellow: #FFDD42;
  --trashie-ui-error-background: #FFEBE5;
  --trashie-ui-crisp-white: #FCFFFD;
  --trashie-ui-soft-mint: #CFFADD;
  --trashie-ui-lime: #16945F;
  --trashie-primary-dream-pop-pink: #FF9AF5;
  --trashie-primary-code-blue: #3333FF;
  --take-back-bags-banana-runtz: #FFFB5D;
  --gradient-light-mint: linear-gradient(180deg, #7DF8A4 0%, #FCFFFD 227.74%);
  --japan-color-rainbow: linear-gradient(91deg, #E13922 -9.03%, #FFFF73 12.49%, #F1BE41 35.24%, #8AFC6E 59.84%, #2B66F6 84.43%, #F2CEFC 109.03%);
  --trashie-ui-sky-boi: #E3F5FF;
  --gradient-light-dream-pop-pink: linear-gradient(180deg, #FF9AF5 0%, #FCFFFD 183.21%);
  --take-back-bags-lemon-handz: #FEF288;
  --take-back-bags-lime: #00A962;
  --trashie-primary-rich-red: #E53200;
  --take-back-bags-marmalade-11: #FF7F50;
}

@font-face {
  font-family: "Gt Maru";
  src: local("Gt Maru"), url(./fonts/GT-Maru-Black.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gt Maru';
  src: local("Gt Maru"), url(./fonts/GT-Maru-Bold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gt Maru';
  src: local("Gt Maru"), url(./fonts/GT-Maru-Medium.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gt Maru Mono';
  src: local("Gt Maru"), url(./fonts/GT-Maru-Mono-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gt Maru';
  src: local("Gt Maru"), url(./fonts/GT-Maru-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gt Maru Emoji Color';
  src: local("Gt Maru"), url(./fonts/GT-Maru-Emoji-Color.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@mixin cta {
  border: 1px solid var(--ui-faded-black);
  border-radius: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  transition: transform .5s cubic-bezier(.165, .84, .44, 1);

  color: var(--ui-faded-black);
  text-align: center;
  font-family: GT Maru;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;

  &:active,
  &:hover {
      outline: 0;
      transform: scale(1.05);
  }
}

@mixin ctaPrimary {
  @include cta;

  background-color: var(--take-back-bags-banana-runtz);
}

@mixin ctaSecondary {
  @include cta;

  background-color: var(--trashie-ui-crisp-white);
}

@mixin ctaTertiary {
  @include cta;

  background-color: var(--trashie-ui-faded-black-30);
}

@mixin ctaQuaternary {
  @include cta;

  border: 1px solid var(--trashie-primary-code-blue);
  background-color: var(--trashie-primary-code-blue);
  color: var(--trashie-ui-crisp-white);
  border-radius: 100px;
}

@mixin ctaQuinary {
  @include cta;

  border: 1px solid var(--trashie-primary-code-blue);
  background-color: transparent;
  color: var(--trashie-primary-code-blue);
  border-radius: 100px;

  &:active,
  &:hover {
    color: var(--trashie-primary-code-blue);
  }
}

@mixin ctaSmall {
  padding: 0;
  font-size: 14px;
}

@mixin ctaMedium {
  padding: 8px 24px;
  border-radius: 10px;
  font-size: 14px;
}

@mixin ctaLarge {
  padding: 16px 32px;
  font-size: 14px;
}

@mixin ctaDisabled {
  opacity: 0.4;
  cursor: default;

  &:hover {
    transform: none;
  }
}

button:hover {
  animation: none !important;
}

@mixin ctaLink {
  color: var(--ui-faded-black);
  text-align: center;
  font-family: GT Maru;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  background-color: unset;
  border: 0;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;

  &:active,
  &:hover {
      outline: 0;
      text-decoration: underline;
  }
}

@mixin animateFadeIn {
  animation-duration: 0.4s;
  animation-name: animate-fade-in;
  animation-delay: 0.4s;
  animation-fill-mode: backwards;
}

@mixin animateEnter {
  animation-duration: 0.6s;
  animation-name: animate-fade-in, animate-enter;
  animation-delay: 0.6s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate-enter {
  0% {
    margin-top: 100px;
  }
  100% {
    margin-top: 0;
  }
}

@mixin ctaOffer {
  border-radius: 24px;
  border: 0.5px solid var(--trashie-primary-code-blue);
  background: var(--trashie-ui-sky-boi);
  color: var(--trashie-primary-code-blue);
  font-size: 16px; 
  padding: 8px 16px !important; 
  margin-top: 16px;
  height: 40px;
  font-weight: 500; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px; 
  text-transform: none;
}

.tooltip {

  &.show,
  &.hide {
    opacity: 1;
  }

  .tooltip-arrow {
    color: var(--trashie-ui-faded-black-100);
  }

  .tooltip-inner {
    background: var(--trashie-ui-faded-black-100);
    max-width: 240px;
    padding: 8px 12px;
    border-radius: 10px;

    font-family: "GT Maru";
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    text-transform: none;
    color: var(--trashie-ui-crisp-white);

    p {
      margin: 0;
    }

    a {
      text-decoration-line: underline;
      color: var(--trashie-ui-crisp-white);
      margin: 0 6px;
    }
  }
}

.markdown {
  * {
    color: var(--trashie-ui-faded-black-60);
    font-family: "GT Maru";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: none;
  }

  ul,
  ol,
  p {
    margin: 0;
  }

  li:not(:last-of-type) {
    margin-bottom: 8px;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: var(--trashie-ui-faded-black-100);
    }
  }
}

// Hide equalweb button
#INDmenu-btn {
  display: none !important;
}
