@import '../../../../scss/_trashie.scss';

.container{
    margin: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .titleStyle{
        color: var(--ui-faded-black);
        font-family: GT Maru;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        text-transform: uppercase;
        align-self: stretch; 
    }
    .subTitleSection{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 5px
    }
    .subTitleStyle{
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .urlTitleStyle{
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-decoration-line: underline;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 1020px) {
    .container{
        .titleStyle{
            font-size: 24px;
        }

        .subTitleStyle{
            font-size: 16px;
        }
    }
}
