.container{
    display: flex;
    flex-direction: column;
    gap: 32px;
    .titleSection{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; 
        .titleStyle{
            color: var(--ui-faded-black);
            font-family: GT Maru;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: uppercase;
        }
        .link{
            color: var(--trashie-ui-faded-black-100);
            font-family: GT Maru;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-decoration-line: underline;
            text-transform: uppercase;
        }
    }
    .content{
        height: 100%;
        width: 100%;
    }
}