@import '../../../../scss/trashie.scss';

.main {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--take-back-bags-banana-runtz);
    border-bottom: 1px solid var(--trashie-ui-faded-black-100);
    min-height: 69px;
    padding: 0 64px;

    .container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        max-width: 1312px;
        margin-left: auto;
        margin-right: auto;

        .text {
            font-family: GT Maru;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            margin: 0;
        }
    }
}

@media only screen and (max-width:1020px) {
    .main {
        padding: 0 20px;
        min-height: 52px;

        .container {
            font-size: 10px;
            height: 52px;

            .text {
                font-size: 10px;
            }
        }
    }
}
