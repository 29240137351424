@import '../../../../../scss/_trashie.scss';

.tcIcon {
  height: 36px;
}

.body-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 432px;
  padding: 80px 32px 48px;

  .summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
  }

  .title {
    color: var(--trashie-ui-faded-black-100);
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    text-align: left;
    margin: 0;
  }

  .message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;

      img {
        max-height: 27px;
        max-width: 27px;
      }
    }

    p {
      color: var(--trashie-ui-faded-black-60);
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      text-align: left;
      margin: 0;
      text-transform: none;
    }
  }
}

.action-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    color: var(--trashie-ui-faded-black-100);
    font-size: 22px;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    margin-bottom: 16px;
  }

  .confirm-button-container {
    width: 100%;
    display: flex;
    justify-content: left;
    .confirm-button{
      width: auto;
    }
    .confirm-button-dialog{
      width: 100%;
    }
  }

  .terms-of-service-checkbox {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 30px;
    padding: 0;

    input {
      border-radius: 5px;
      border: 2px solid var(--trashie-ui-faded-black-100-b);
      width: 18px;
      height: 18px;
      margin: 2px 0 0;
      cursor: pointer;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }

    Input:checked {
      background-color: var(--japan-color-hard-mint);
    }
  }

  .link {
    color: #33F;
  }

  .cancel-option {
    color: var(--trashie-ui-faded-black-100);
    font-family: "GT Maru";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 21px */
    text-decoration-line: underline;
    text-transform: uppercase;
    margin-top: 32px;
    cursor: pointer;
  }
}

.body-style.confirm {
  padding: 0;

  .body-top {
    width: 100%;
  }

  .body-bottom {
    width: 100%;
    padding: 40px 32px 25px;

    .content {
      margin-bottom: 32px;
    }
  }
}

.body-style.loading {
  padding: 112px 84px;
  background-color: var(--take-back-bags-lemon-handz);

  .body-top {
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-height: 200px;

    .trashie-logo {
      width: 100%;
      display: flex;
      align-items: center;

      .trashie-coin-logo {
        margin: 0 auto;
        height: 40px;
      }
    }

    .loading-text {
      color: var(--trashie-ui-crisp-white);
      text-align: center;
      text-shadow: 3px 3px 0px #1B1A1B;
      transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
      opacity: 1;
      transform-style: preserve-3d;
      -webkit-text-stroke-width: 1.5px;
      -webkit-text-stroke-color: var(--trashie-ui-faded-black-100);
      font-family: "GT Maru";
      font-size: 48px;
      font-style: normal;
      font-weight: 900;
      line-height: 90%;
      text-transform: uppercase;
    }
  }
}

.body-style.error-modal {
  .body-top {
    .error-title {
      display: flex;
      align-items: center;

      // TODO: modify this once the TitleSection component gets the module scss removed
      >div {
        justify-content: center;
        margin: 0 auto !important;
        text-align: center;

        >div {
          text-align: center;
          align-items: center;
          width: 100%;

          div,
          span {
            text-align: center;
            align-items: center;
            width: 100%;
          }
        }
      }
    }

    .error-text {
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin-top: 32px;

      .title,
      .description {
        margin: 0;
        color: var(--trashie-ui-faded-black-100);
      }

      .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
      }

      .description {
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
      }
    }

    .error-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      margin-top: 32px;


      .contact-support-btn {
        @include ctaLink;
        @include ctaSmall;
      }
    }
  }
}

.body-style.unavailable-modal {
  .body-top {
    .unavailable-title {
      display: flex;
      align-items: center;

      // TODO: modify this once the Title section component gets the module scss removed
      >div {
        justify-content: center;
        margin: 0 auto !important;
        text-align: center;

        >div {
          text-align: center;
          align-items: center;
          width: 100%;

          div,
          span {
            text-align: center;
            align-items: center;
            width: 100%;
          }
        }
      }
    }

    .unavailable-text {
      width: 80%;
      margin: 32px auto;

      .title {
        color: var(--trashie-ui-faded-black-100);
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        text-align: center;
        margin: 0;
      }

      .bags-image {
        width: 100%;
      }
    }

    .unavailable-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;

      .get-take-back-bag-btn {
        @include ctaPrimary;
        @include ctaLarge;
      }
    }
  }
}

.body-style.merchant_main {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  justify-content: space-between;

  .merchant-info {
    display: flex;
    margin: 0;
    padding: 32px 16px 32px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 374px;

    .merchant-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 326px;
      height: 300px;
      border-radius: 12px;
      flex-shrink: 0;
      background-position: center;  
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      padding: 0px 24px;
      position: relative;       
      max-width: 100%;
      overflow: hidden;
      cursor: pointer;
      .merchant-logo{
        max-width: 100%;
        filter: brightness(0) invert(1);
      }
    }

    .description-container {
      color: var(--trashie-ui-faded-black-60);
      font-family: "GT Maru";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      max-width: 326px;
      text-transform: none;

      .description-hidden {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .see-more{
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .rewardInfo {
    display: flex;
    max-width: 646px;
    padding: 24px 32px 32px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;

    .action-container {
      width: 100%;

      .CompoundRadioSelector {
        width: 100%;
      }
    }

    .content {
      width: 100%;
    }
  }
}

.body-style.terms{
  display: flex;
  padding: 16px 16px 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 48px;
  
  .content{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    &__logo{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px; 
      height: 301px;
      padding: 0px 24px;
      flex-shrink: 0;
      background-position: center;  
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
      &--merchant-logo{
        max-width: 100%;
        filter: brightness(0) invert(1);
      }
    }
    &__title{
      color: var(--trashie-ui-faded-black-100);
      font-family: "GT Maru";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;
    }
    &__terms{
      color: var(--trashie-ui-faded-black-60,);
      font-family: "GT Maru";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
      padding: 0;
    }
  }
  .bottom{
    width: 100%;
    display: flex;
    justify-content: right;
    .close-button{
      max-width: 116px;
    }
  }
}

.body-style.final {
  display: flex;
  width: 100%;
  padding-bottom: 32px;
  flex-direction: column;
  padding: 0;

  .top {
    width: 100%;
  }

  .bottom {
    width: 100%;
    padding: 32px 32px;

    &--content {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .subtitle {
        color: var(--trashie-ui-faded-black-100);
        font-family: "GT Maru";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      .description {
        color: var(--trashie-ui-faded-black-60);
        font-family: "GT Maru";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;

        a {
          text-decoration: underline;
          color: var(--trashie-primary-code-blue);
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background: var(--trashie-ui-faded-black-10);
        padding: 16px;
        gap: 16px;
        margin-top: 10px;

        &__item {
          display: flex;
          flex-direction: column;

          &--title {
            color: var(--trashie-ui-faded-black-100);
            font-size: 12px;
            font-weight: 700;
            line-height: 150%;
            margin: 0;
          }

          &--data-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            p {
              font-size: 16px;
            }
          }

          &--data {
            margin: 0;
            width: fit-content;
            text-transform: none;
            color: var(--trashie-ui-faded-black-60);
            font-family: Courier;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            background-color: unset;
          }

          &--link {
            color: var(--trashie-primary-code-blue);
            text-decoration-line: underline;
          }
        }
      }

      .cta-container {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
        margin: 26px 26px;

        .cta-shop {
          @include ctaLarge;
          @include ctaPrimary;
          max-width: 320px;
        }
      }

      .redirectSection{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        &__title{
          color: var(--trashie-ui-faded-black-100);
          font-family: "GT Maru";
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          text-transform: uppercase;
        }
        &__text{
          color: var(--trashie-ui-faded-black-60);
          font-family: "GT Maru";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-transform: none;
        }
        &__cta{
          width: 100%;
          display: flex;
          justify-content: center;
          &--button{
            max-width: 320px;
            padding: 8px 16px; 
          }
        }
      }

      .summary {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-top: 16px;
        margin-bottom: 20px;
        width: 100%;
        justify-content: space-between;

        .title {
          color: var(--trashie-ui-faded-black-100);
          font-family: "GT Maru";
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          text-transform: uppercase;
          margin-bottom: 0;
        }

        .message {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          width: 100%;

          .icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
          }

          p {
            margin-bottom: 0;
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: none;
          }
        }
      }

      .collapsable-sections-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 16px;
        gap: 16px;

        .collapsable-sections-content {
          padding-bottom: 16px;
        }

        .collapsable-sections-md-content {
          padding: 16px 0;
        }
      }
    }

    &--disclaimer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      .title {
        color: var(--trashie-ui-faded-black-100);
        font-family: "GT Maru";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
      }

      .terms {
        color: var(--trashie-ui-faded-black-100, #292829);
        font-family: "GT Maru";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        flex: 1 0 0;
        text-transform: none;
      }
    }
  }
}

@media screen and (max-width: 1020px) {

  .body-style.available {
    .body-bottom {
      padding: 16px 16px 25px;
    }
  }

  .body-style.merchant_main {
    flex-direction: column;

    .merchant-info {
      padding: 16px 16px 0px;
      max-width: 100%;
      width: 100%;

      a {
        width: 100%;
      }

      .merchant-image {
        width: 100%;
      }
      .description-container {
        max-width: 100%;
      }
    }

    .rewardInfo {
      align-items: flex-start;
      max-width: 100%;
      padding: 0px 16px 16px 16px;

      .action-container {
        .confirm-button-container {
          display: none;
        }
      }
    }
  }

  .body-style.final {
    .bottom {
      padding: 0px 16px 32px;

      .cta-container {
        display: none;
      }
    }

  }
}
