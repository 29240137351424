.RewardFlag {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 8px 0;
    padding: 4px 8px 4px 6px;
    border: 0;
    background: var(--ui-crisp-white);

    color: var(--trashie-ui-faded-black-100);
    font-size: 8px;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    z-index: 1;

    p {
        margin: 0;
    }

    &.soldOut {
        background: var(--trashie-ui-thrifty-sky);
    }

    &.userLimit {
        background: var(--japan-color-hard-mint);
    }

    &.NEW {
        background: var(--trashie-primary-thrifty-blue);
    }

    &.none {
        background: var(--trashie-primary-dream-pop-pink);
    }
}
