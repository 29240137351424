.OffersList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 20px;
    background: var(--trashie-ui-faded-black-10);
    padding: 0 16px;
    align-items: flex-start;
    gap: 16px;
    justify-content: flex-start;
}

@media screen and (max-width: 768px) {
    .OffersList {
        gap: 12px 8px;
    }
}
