.HeroSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 16px 32px 16px;
    gap: 48px;
    justify-content: center;
    align-items: center;

    &__container {
        display: flex;
        flex-direction: row;
        gap: 32px;
        max-width: 1312px;
        width: 100%;
    }

    &__navigation{
        max-width: 1312px;
        width: 100%;
    }
}

@media screen and (max-width: 1020px ) {
    .HeroSection {
        padding: 32px 16px 32px 16px;
        gap: 48px;
        background: var(--trashie-ui-faded-black-20) url('../../images/dashboard-rainbow.png') no-repeat right top / 200px;
            
        &__container {
            display: flex;
            flex-direction: column;
        }
    }
}