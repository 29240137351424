.ListPaginator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;

    &__button {
        gap: 12px;
        width: fit-content;

        img {
            height: 24px;
        }
    }

    &__pages {
        display: flex;
        flex-direction: row;

        &--button {
            padding: 8px 12px;
            text-decoration: none;
            font-size: 18px;

            &:disabled {
                text-decoration: none;
                opacity: 0.6;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .ListPaginator {
       &__button {
            span {
                display: none;
            }
       }
    }
}
