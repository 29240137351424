@import '../../../../../scss/vars';

.advice-container{
    color: var(--trashie-ui-faded-black-60); 
    font-family: "GT Maru";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: none;
    ul{
        margin-bottom: 0;
    }
}
