@import '../../../../scss/trashie';

.Transactions {
    &__noData {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-top: 40px;
        padding: 48px 0;
        gap: 32px;

        &--image {
            max-height: 120px;
        }

        &--message {
            max-width: 840px;
            font-size: 24px;
            font-weight: 700;
            line-height: 140%;
            text-align: center;
            text-transform: uppercase;
        }

        &--buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            gap: 32px;
            flex-wrap: wrap;

            .buttonPrimary {
                @include ctaPrimary;
                @include ctaLarge;

                max-width: 320px;
            }

            .buttonSecondary {
                @include ctaSecondary;
                @include ctaLarge;

                max-width: 320px;
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .Transactions {
        &__noData {
            &--Message {
                font-size: 20px;
            }
        }
    }
}
