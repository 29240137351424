@import '../../../../../../scss/trashie';

.EnterNewAddressView {
    display: flex;
    flex-direction: column;
    gap: 16px;


    .continue-button {
        margin-top: 8px;
    }

    .input {
        background: #FCFFFD;
    }

    .input-error {
        border-color: #FF7F50 !important;
    }

    .inputText {
        background: #FCFFFD !important;
    }

    .zip-error {
        border-radius: 4px;
        color: var(--take-back-bags-marmalade-11) !important;
        margin: 0 0 8px;
        padding: 8px 0;
        text-align: center;
        text-align: start;
    }

    // Google Maps Suggestions styles

    /* Styles the container of the autocomplete suggestions */
    .pac-container {
        border-radius: 10px;
        border: 1px solid var(--Trashie-UI-Faded-Black-30, #D9D9D9);
        background: var(--Trashie-UI-Crisp-White, #FCFFFD);
    }

    /* Styles individual autocomplete suggestions */
    .pac-item {
        padding: 10px;
        color: var(--Trashie-UI-Faded-Black-40, #A9A9A9);
        font-family: "GT Maru";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 27px */
        cursor: pointer;
        text-align: left;
    }

    /* Highlighting effect when hovering over an item */
    .pac-item:hover {
        background-color: #f0f0f0;
    }
}
