.CompoundRadioSelector {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 16px 0;
    gap: 16px;
    color: var(--trashie-ui-faded-black-100);

    p {
        margin: 0;
    }

    a {
        text-decoration: underline;
    }

    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
    }

    &__section {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;

        &--title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 6px;

            &-icon {
                height: 20px;
            }

            &-text {
                font-size: 14px;
                font-weight: 700;
                line-height: 150%;
                text-transform: uppercase;
            }
        }

        &--extra {
            display: flex;
            width: 100%;
            margin-top: 8px;
            font-size: 14px;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
        }
    }
}
