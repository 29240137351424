@import "../../../../scss/trashie";

.GoogleButton {
    @include ctaSecondary;
    @include ctaLarge;

    width: 100%;
    padding: 16px 32px;
    border-radius: 100px;
    border: 1px solid var(--trashie-ui-faded-black-40); 

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 24px;
            margin-right: 0.5rem;
        }
    }
}
