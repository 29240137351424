.DashboardNewSection {
    display: flex;
    justify-content: center;
    width: 100%;
    scroll-margin-top: 90px;

    &__container {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        width: 100%;
        gap: 8px;
        max-width: 1344px; // width + padding
        padding: 32px 0;
        min-height: 200px;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-size: 20px;
            font-weight: 500;
            line-height: 100%;
            margin: 0 16px;
            text-transform: none;
        }

        &--subTitle {
            color: var(--trashie-ui-faded-black-70);
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            margin: 0 16px;
            text-transform: none;
            padding-bottom: 8px;
            font-family: "GT Maru";
        }

        &--content {
            display: flex;
            width: 100%;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-flow: column;

            font-size: 20px;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
        }

        &--bottom{
            width: 100%;
            display: flex;
            justify-content: left;
        }
        &--cta {
            max-width: 238px;
            width: 100%;
            margin: 0 16px;
        }

        &--loader {
            margin: 80px auto;
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .DashboardNewSection {
        &__container {
            padding: 24px 0;

            &--title {
                font-size: 16px;
                line-height: 150%;
            }

            &--subTitle {
                font-size: 12px;
                padding-bottom: 4px;
            }

            &--subTitle {
                font-size: 12px;
            }

            &--cta {
                max-width: 100%;
            }
        }
    }
}
