.offers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 20px;
    background: var(--trashie-ui-faded-black-10);
    padding: 0 16px;
    align-items: flex-start;
    gap: 16px;
    justify-content: flex-start;
}

@media screen and (max-width: 1020px) {
    .offers {
        gap:  16px;
        justify-content: space-between;
    }
}

@media screen and (max-width: 375px) {
    .offers {
        gap: 16px 8px;
        justify-content: space-around;
    }
}