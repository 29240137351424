.main {
    display: flex;
    width: 100%;
    padding: 64px;
    background: var(--trashie-ui-faded-black-10);

    .container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;
        gap: 32px;
    }

    .contentAccount {
        max-width: 960px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .headerInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;

        .redirectContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
    
            .button {
                max-width: 256px;
                height: 53px;
            }
        }
    }

    .userTitle {
        color: var(--ui-faded-black);
        font-family: GT Maru;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        text-transform: uppercase;
    }

    .emailTitle {
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
    }

    .sinceTitle {
        color: var(--trashie-ui-faded-black-60);
        font-family: GT Maru;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
    }


    .userInfo {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 20px;
        background: var(--trashie-ui-crisp-white);
        max-height: 147px;
        height: 100%;
        gap: 16px;

        .name {
            color: var(--ui-faded-black);
            font-family: GT Maru;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: uppercase;
        }
    }

    .tbbNoDataSection {
        border-radius: 20px;
        padding-bottom: 64px;
        background: var(--trashie-ui-crisp-white);
    }

    .sectionContainer{
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .spliter{
        border: 1px solid var(--ui-wow-light-gray);
    }
}

@media screen and (max-width: 1020px) {
    .main {
        padding: 32px 16px;

        .contentAccount {
            max-width: 375px;
            gap: 48px;
        }

        .userTitle {
            font-size: 22px;
        }

        .emailTitle {
            font-size: 14px;
            max-width: 375px;
            overflow-wrap: break-word;
        }

        .sinceTitle {
            font-size: 12px;
        }

        .headerInfo {
            .redirectContainer {
                flex-direction: column;
                gap: 16px;
                align-items: center;
                width: 100%;
                .button {
                    max-width: 375px;
                    height: 53px;
                }
            }
        }

        .tbbNoDataSection {
            padding-bottom: 32px;
        }

        .userInfo {
            height: 100%;
            max-height: 177px;
        }
        .sectionContainer{
            gap: 48px;
        }
    }
}
