.InputNew {
    width: 100%;

    &__label {
        color: var(--trashie-ui-black);
        font-family: GT Maru;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__element {
        width: 100%;
        padding: 12px;
        border-radius: 10px;
        border: 1px solid var(--trashie-ui-faded-black-30) !important;
        background: var(--trashie-ui-crisp-white);
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        gap: 8px;

        &.active {
            border: 1px solid var(--trashie-ui-faded-black-30) !important;
        }

        &.error {
            border: 1px solid var(--take-back-bags-marmalade-11) !important;
        }

    }

    &__input {
        width: 100%;
        border: 0;
        background: var(--trashie-ui-crisp-white) !important;
        color: var(--trashie-ui-faded-black-100);
        font-family: GT Maru;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        padding: 0;

        &.password {
            text-transform: none;
        }

        &::placeholder {
            color: var(--trashie-ui-faded-black-40) !important;
            text-transform: capitalize;
        }

        &:active,
        &:focus,
        &:focus-visible {
            border: 0 !important;
            box-shadow: none !important;
            padding: 0;
            outline: 0;
        }
    }

    &__error {
        color: var(--take-back-bags-marmalade-11) !important;
        text-align: center;
        font-family: GT Maru;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        text-align: start;
        margin: 0 0 8px;
        padding: 8px 0;
        border-radius: 4px;
    }

    &__instructions {
        color: var(--trashie-ui-faded-black-70);
        font-family: "GT Maru";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}
