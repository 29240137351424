.RegisterPin {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    &__pin {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 16px;
    }

    &__option {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 32px;

        &--text{
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            margin: 0;
        }
    }
}
