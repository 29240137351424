.TermsDisclaimer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    p {
        margin-bottom: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 8px;

        &--title {
            color: var(--trashie-ui-Ffaded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            text-transform: uppercase;
        }

        &--body {
            color: var(--trashie-ui-faded-black-60);
            font-family: "GT Maru";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            text-transform: none;
            max-height: 94px;
            overflow: hidden;

            &--text {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    &__cta{
        cursor: pointer;
        color: var(--trashie-ui-faded-black-100);
        font-family: "GT Maru";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-decoration-line: underline;
        text-transform: uppercase;
    }
}
