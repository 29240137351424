@import '../../../../scss/_trashie.scss';

.Profile {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 350px;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 65px 0;

        .container {
            width: 100%;

            .separator {
                display: flex;
                width: 100%;
                height: 1px;
                background: var(--ui-wow-light-gray);
                margin: 12px 0;
            }

            .password {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 24px;

                &__title {
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 150%;
                    text-transform: uppercase;
                }
            }
        }
    }

    .loader {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
}

@media screen and (max-width: 1020px) {
    .Profile {
        margin-left: 0;

        .content {
            margin: 40px 20px 65px;

            .password {
                &__title {
                    font-size: 18px;
                }
            }
        }

        .loader {
            margin: 16px;
        }
    }
}
