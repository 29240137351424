@import '../../../../../scss/trashie';

.RewardsViewItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 140px;
    padding: 0;
    border: 0;
    border-radius: 8px;
    background: var(--ui-crisp-white);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    transition: transform .5s cubic-bezier(.165, .84, .44, 1);
    overflow: hidden;
    position: relative;
    width: calc(100% * (1/4) - 32px + 8px);

    &:hover {
        transform: scale(1.025);
    }

    &__cover {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 100%;
        flex-shrink: 0;
        background-position: center;  
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding: 12px;
        overflow: hidden;

        &--logo {
          max-width: 100%;
          filter: brightness(0) invert(1);
        }

        &.disabled {
            opacity: 0.5;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        padding: 16px 0;
        width: calc(100% - 110px);

        p {
            margin: 0;
        }

        &--text {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 0 16px 0;
            width: 100%;
        }

        &--chip {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 6px;
            padding: 0 16px 0;
            width: 100%;
        }

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-size: 16px;
            font-weight: 700;
            line-height: 120%;
            text-transform: uppercase;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--subtitle {
            color: var(--trashie-ui-faded-black-50);
            font-size: 12px;
            font-weight: 400;
            line-height: 120%;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &--amount {
            width: fit-content;
            max-width: 100%;
            padding: 8px;
            border-radius: 4px;
            border: 1px solid var(--trashie-ui-faded-black-100);
            background: var(--ui-crisp-white);
            box-shadow: 2px 2px 0px 0px var(--trashie-ui-faded-black-100);
            font-size: 14px;
            line-height: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--trashie-ui-faded-black-100);

            sup {
                font-size: 9px;
                margin-left: 2px;
            }
        }

        &__trashieCash {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 2px;
            margin-top: 3px;

            &--exchangeValue {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 12px;
                font-weight: 500;
                line-height: 150%;
                padding-top: 1px;
            }
        }
    }

    &.disabled {
        .RewardsViewItem__cover,
        .RewardsViewItem__content {
            opacity: 0.6;

            .RewardItem__content--amount {
                background: linear-gradient(to top right,
                    rgba(0,0,0,0) 0%,
                    rgba(0,0,0,0) calc(50% - 0.8px),
                    rgba(0,0,0,1) 50%,
                    rgba(0,0,0,0) calc(50% + 0.8px),
                    rgba(0,0,0,0) 100%);
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .RewardsViewItem {
        width: calc(100% * (1/2) - 16px + 4px);
    }
}

@media screen and (max-width: 768px) {
    .RewardsViewItem {
        width: 100%;
    }
}
