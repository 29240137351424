@import '../../../../scss/trashie';

.RewardsView{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 350px;
    overflow-x: clip;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        max-width: 1344px; // width + padding
        padding: 16px 0 0;

        &--slider {
            display: flex;
            max-height: 320px;
            width: 100%;
            margin: 16px 0;
            padding: 0 16px;
            overflow: hidden;

            .splide {
                overflow: hidden;
                border-radius: 8px;
            }

            &-slide {
                display: flex;
                width: 100%;
                height: 100%;
                max-height: 100%;
                border-radius: 8px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        &--header {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-content: flex-start;
            padding: 0 16px;
            margin: 16px 0 24px;

            &-title {
                color: var(--trashie-ui-faded-black-100);
                font-size: 28px;
                font-weight: 700;
                line-height: 140%;
                text-transform: none;
                margin: 0;
            }

            &-subtitle {
                color: var(--trashie-ui-faded-black-80);
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;
                text-transform: none;
                margin: 0;
            }
        }

        &--loader {
            margin: 120px auto;
            width: 100%;
        }

        &--button {
            width: fit-content;
        }

        &--section{
            margin-top: 16px;
            background: var(--ui-crisp-white);
            border-radius: 20px;
            overflow: hidden;
        }

        &--controls {
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: center;
            position: sticky;
            top: 72px;
            z-index: 9;
            background-color: var(--trashie-ui-faded-black-10);
            width: 100%;
            padding: 0 16px;
            scroll-margin-top: 72px;
            align-items: center;
            gap: 16px;

            &.hasEnvBanner {
                top: 90px;
                scroll-margin-top: 90px;
            }

            &-options {
                display: flex;
                flex-direction: row;
                gap: 10px;
                width: fit-content;
                padding: 4px 0;
            }
        }

        &--pane {
            width: 100%;
            min-height: 400px;
            margin: 32px 0;
            scroll-margin-top: 176px;

            &.hasEnvBanner {
                scroll-margin-top: 192px;
            }
        }

        &--page{
            padding: 0px !important;
            .PageContent__container {
                padding: 0px;
            }
        }

        &--scroll {
            // Hide scrollbars
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &--empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;
            margin: 32px auto;
            width: 100%;
            max-width: 325px;
            padding: 16px;

            &-icon {
                height: 32px;
            }

            &-title {
                font-family: "GT Maru";
                font-size: 18px;
                font-weight: 700;
                line-height: 120%;
                color: var(--trashie-ui-faded-black-100);
                text-align: center;
            }

            &-subtitle {
                font-family: "GT Maru";
                font-size: 16px;
                font-weight: 400;
                line-height: 120%;
                color: var(--trashie-ui-faded-black-100);
                text-align: center;
            }
        }
    }
    &__ctaContainer{
        display: none;
    }
}

@media screen and (max-width: 1020px) {
    .RewardsView {
        &__content {
            margin: 0 16px 32px;

            &--slider {
                margin: 8px 0;
                padding: 0 16px 16px;
                max-height: 800px;
            }

            &--header {
                margin: 16px 0;

                &-title {
                    font-size: 20px;
                }

                &-subtitle {
                    font-size: 14px;
                }
            }

            &--controls {
                flex-direction: column;
                top: 63px;
                align-items: stretch;

                &.hasEnvBanner {
                    top: 78px;
                }

                &-options {
                    width: 100%;

                    > div {
                        width: 50%;
                    }
                }
            }

            &--sorting {
                width: 100%;
                padding: 16px;
                position: sticky;
                top: 158px;
                z-index: 9;
                height: 100%;
                background: var(--trashie-ui-faded-black-10);
            }

            &--pane {
                margin: 16px 0;
                scroll-margin-top: 206px;

                &.hasEnvBanner {
                    scroll-margin-top: 224px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .RewardsView {
        &__content {
            &--slider {
                max-height: 420px;
                margin: 0;
            }
        }
    }
}
