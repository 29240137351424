.EyeToggle {
    height: 24px;
    width: 24px;

    &__button {
        margin: 0;
        padding: 0;
        border: 0;
        background-color: unset;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }
    }
}