@import '../../../../../scss/trashie';

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.ShopifyUpgradeBanner {
    height: 40.5px;
    display: flex;
    align-items: center;

    &__content {
        background-color: #231f20;
        height: 40.5px;
        display: flex;
        flex-direction: row;
        justify-content: left;
        z-index: 9999;
        position: fixed;
        width: 100%;
        overflow: hidden;
        gap: 80px;
        align-items: center;
        padding: 0 40px;
    }

    &__text {
        display: inline-block;
        white-space: nowrap;
        font-size: 14px;
        color: white;
        text-transform: none;
        font-family: "BasicCommercialLTStd-Lt";
        font-weight: 500;
        cursor: pointer;
        .circle {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ff674c;
            margin-right: 5px;
            animation: blink 1s infinite;
        }
        &:hover {
            color: white;
        }
    }
}

@media screen and (max-width: 1020px) {
    .ShopifyUpgradeBanner {
        &__content {
            gap: 20px;
            padding: 0 10px;
        }

        &__text {
            font-size: 10px; 
            white-space: normal;
            line-height: 1.2; 
            text-align: left;
        }
    }
}
