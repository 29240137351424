.Filter {
    max-width: 135px;
    width: 100%;

    &__button {
        padding: 8px 12px;
        font-size: 12px;
        border-radius: 20px;
        border: 1px solid var(--trashie-ui-faded-black-30);
        gap: 4px;
        text-transform: none;

        &--label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &--count {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 10px;
            font-weight: 500;
            line-height: 150%;
            color: var(--trashie-ui-crisp-white);
        }
    }
    &__modal {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 24px 8px 48px 8px;
        width: 375px;
    
        &--title {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            padding: 0px 16px 12px 16px;
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            align-items: center;
            border-bottom: 1px solid var(--trashie-ui-faded-black-30); 
    
            &--close {
                cursor: pointer;
            }
        }

        &--filters {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0px 16px 12px;

            &-title {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-weight: 700;
                line-height: 150%;
            }
        }

        &--buttons {
            padding: 0 16px;

            &-label {
                width: 100%;
                padding: 0 0 16px;
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 16px;
                font-weight: 700;
                line-height: 150%;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .Filter {
        max-width: 100%;

        &__button {
            padding: 8px 16px;
            font-size: 12px;
            border-radius: 100px;
            gap: 4px;
        }

        &__modal {
            width: 100%;
        }
    }
}
