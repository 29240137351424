.customForm {
    width: 100%;
    display: flex;
    padding: 0;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;

    .formTitleStyle {
        color: var(--ui-faded-black);
        font-family: GT Maru;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        text-transform: uppercase;
    }

    .formSubtitleStyle {
        color: var(--ui-faded-black);
        font-size: 18px;
        font-weight: 400;
        line-height: 150%;
        text-transform: uppercase;
        margin: 0;
    }

    .formSectionStyle {
        margin: 32px 0;

        .formSectionsButtons {
            width: 100%;
        }

        .formSectionTitle {
            color: var(--ui-faded-black);
            font-size: 22px;
            font-weight: 500;
            line-height: 150%;
            text-transform: uppercase;
            margin-bottom: 32px;
        }

        .formSectionSubtitle {
            color: var(--ui-faded-black);
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            text-transform: uppercase;
        }

        .formRows {
            display: flex;
            width: 100%;
            align-items: stretch;

            .formRowTitle {
                font-style: normal;
            }

            .formRowInputs {
                display: flex;
                gap: 1%;
                align-items: stretch;
                width: 100%;

                .formRowInputContainer {
                    display: flex;
                    width: 100%;
                }
            }
        }
    }


    .formItemError {
        color: var(--japan-color-dry-red);
        background-color: var(--trashie-ui-error-background);
        text-align: center;
        font-family: GT Maru;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        margin: 16px 0 0;
        padding: 8px;
        border-radius: 4px;
    }
}

@media screen and (max-width: 1020px) {
    .customForm {
        min-width: 400px;
        min-width: 0;
        padding: 0;

        .formTitleStyle {
            font-size: 24px;
        }

        .formSubtitleStyle {
            font-size: 16px;
        }

        .formSectionStyle {
            .formSectionTitle {
                font-size: 18px ;
            }
        }
    }
}
