a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: $dark;
}

.btn {
  border-color: $dark;
  padding: 0.5rem 1.5rem;
}

.btn-outline-primary {
  color: darken($primary, 75%);
}

.btn-outline-secondary {
  color: darken($secondary, 75%);
}

.btn-outline-success {
  color: darken($success, 50%);
}

.btn-outline-info {
  color: darken($info, 20%);
}

.btn-outline-warning {
  color: darken($warning, 20%);
}

.btn-outline-danger {
  color: darken($danger, 20%);
}

.btn-link {
  border: none;
}

.btn-danger {
  color: white;
}

.text-secondary {
  color: darken($secondary, 55%) !important;
}

.text-success {
  color: darken($success, 55%) !important;
}

.text-info {
  color: darken($info, 55%) !important;
}

.display-1,
.display-2 {
  font-family: 'Knockout' !important;
}
