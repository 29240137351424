@import'../../../../../../scss/trashie';

.CardSection {
    width: 100%;
    border-radius: 20px;
    background: var(--trashie-ui-crisp-white);
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    &__container {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        width: 100%;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: uppercase;
        }

        &--content{
            width: 100%;
        }
    }
    &__footer{
        width: 100%;
    }
}