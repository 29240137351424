.InputPin {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    
    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 8px;

        &--pin-field {
            display: flex;
            flex: 1;
            align-items: center;
            height: 60px;
            min-width: 45px;
            width: auto;
            padding: 12px;
            border-radius: 10px;
            border: 1px solid var(--trashie-ui-faded-black-30);
            background: var(--trashie-ui-crisp-white);
            color: var(--trashie-ui-faded-black-100);
            text-align: center;
            font-family: "GT Maru";
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;

            &::placeholder {
                color: var(--trashie-ui-faded-black-40);
            }

            &:focus-visible {
                border: 1px solid var(--trashie-ui-faded-black-60);
                outline: var(--trashie-ui-faded-black-60);
            }

            &.error {
                border: 1px solid var(--take-back-bags-marmalade-11);
                color: var(--take-back-bags-marmalade-11);
            }
        }
    }

    &__error {
        display: flex;
        width: 100%;
        color: var(--take-back-bags-marmalade-11);
        font-family: "GT Maru"; 
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
    }
}
