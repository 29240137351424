@import '../../../../scss/_trashie.scss';

.LoadingBar {
    width: 90%;
    max-width: 300px;
    margin: 12px auto;
    border: 2px solid var(--ui-faded-black);
    border-radius: 12px;

    &__progress {
        background: var(--japan-color-rainbow);
        width: 0%;
        transition: width 0.6s ease;
    }
}
