.EnvironmentBanner {
    height: 18px;

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        z-index: 9999;
        position: fixed;
        width: 100%;
        overflow: hidden;
        gap: 80px;
    }

    &__text {
        display: inline-block;
        white-space: nowrap;
        font-size: 12px;
        color: white;
        text-transform: uppercase;
        font-family: 'Gt Maru';
        font-weight: 500;
    }
}

@media screen and (max-width: 1020px) {
    .EnvironmentBanner {
        height: 15px;

        &__content {
            gap: 20px;
        }

        &__text {
            font-size: 10px; 
        }
    }
}
