.sectionPretitle {
    color: var(--trashie-ui-faded-black-100);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
    border-radius: 10px;
    border: 1px solid var(--trashie-ui-faded-black-100);
    padding: 8px 12px;
}

.shadowedText {
    color: var(--trashie-ui-crisp-white);
    text-shadow: 2px 2px 0px var(--trashie-ui-faded-black-100);
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: var(--trashie-ui-faded-black-100);
}

.sectionShadowedTitle {
    @extend .shadowedText;

    text-align: left;
    font-size: 120px;
    font-weight: 900;
    line-height: 90%;
    text-transform: uppercase;
}

.sectionSubtitle {
    color: var(--trashie-ui-faded-black-100);
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    text-transform: none;
}

.titleSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .contentText {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 60%;
        text-align: left;
        align-items: self-start;

        .pretitleStyle {
            @extend .sectionPretitle;
        }

        .shadowedTitle {
            @extend .sectionShadowedTitle;
            font-size: 48px;
        }

        .subtitleStyle {
            @extend .sectionSubtitle;
        }
        .preSubTitleStyle{
            color: var(--trashie-ui-faded-black-100);
            font-weight: 700;
        }
        .containerSubtitleStyle{
            display: flex;
            flex-direction: column;
        }

        .subtitleContainer{
            display: flex;
            flex-direction: column;
        }
    }

    .contentCta {
        display: flex;
        align-self: flex-end;
    }
}

@media screen and (max-width: 1020px) {
    .titleSection {
        align-items: self-start;
        text-align: left;
        margin-bottom: 0;

        .contentText {
            width: 50%;
            align-items: left;

            .pretitleStyle {
                font-size: 12px;
                font-weight: 700;
            }
            .preSubTitleStyle{
                color: var(--trashie-ui-faded-black-100);
                font-weight: 800 !important;
            }
            .shadowedTitle {
                text-align: left;
                font-size: 32px;
                font-weight: 900;
                line-height: 120%;
                text-shadow: 1.5px 1.5px 0px var(--trashie-ui-faded-black-100);
                -webkit-text-stroke-width: 1px;
            }

            .subtitleStyle {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .titleSection {
        .contentText {
            width: 100%;
        }

        .contentCta {
            display: none;
        }
    }
}
