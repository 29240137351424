.NavigationMenu {
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        padding: 16px 64px 24px 64px;
        border-radius: 8px;
        background: var(--trashie-ui-crisp-white);
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
    }
}

@media screen and (max-width: 1020px) {
    .NavigationMenu {
        &__container {
            padding:0px;
            background: none;
            box-shadow: none;
        }
    }
}