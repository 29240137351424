@import '../../../../scss/trashie';

#shopify-embed-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    background-color: var(--ui-crisp-white);
    font-family: "BasicCommercialLTStd-Lt";

    .ShopifyCheckout {
        position: relative;
        user-select: none;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 16px;
        padding: 48px 32px 32px;
        background-color: var(--ui-crisp-white);

        &__content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            * {
                @include animateFadeIn;
            }
        }

        p {
            margin: 0;
        }

        &__close {
            position: absolute;
            top: 0;
            right: 0;
            border: 0;
            padding: 0;
            margin: 4px;
            background-color: var(--ui-crisp-white);
            z-index: 1;
        }

        &__logo {
            margin-bottom: 16px;
            height: 20px;

            img {
                height: 100%;
            }
        }

        &__info {
            &--title {
                font-family: "A2RecordGothicCondensed";
                color: var(--trashie-ui-faded-black-100);
                font-size: 24px;
                font-weight: 700;
                line-height: 100%;
            }

            &--description {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
            }
        }

        &__selector {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 10px;
            background: var(--trashie-ui-faded-black-10);
            padding: 16px;

            color: var(--trashie-ui-faded-black-60);
            font-weight: 400;
            line-height: 120%;

            &--label {
                font-size: 14px;
            }


            &--range {
                position: relative;

                &::after {
                    content: '';
                    display: flex;
                    flex: 1;
                    height: 1px;
                    background-color: var(--trashie-ui-faded-black-100);
                    position: absolute;
                    top: 32px;
                    left: 0;
                    right: 0;
                    z-index: 0;
                    cursor: pointer;
                }

                &-input {
                    appearance: none;
                    -webkit-appearance: none;
                    width: 100%;
                    background: transparent;
                    margin: 26px 0 22px 0;
                    position: relative;
                    z-index: 1;

                    @mixin range-thumb {
                        appearance: none;
                        border: 1px solid var(--trashie-ui-faded-black-30);
                        height: 36px;
                        width: 36px;
                        border-radius: 50%;
                        background: var(--ui-crisp-white);
                        cursor: pointer;
                        margin-top: -12px;
                        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
                    }

                    &::-ms-thumb {
                        @include range-thumb;
                    }
                    &::-moz-range-thumb {
                        @include range-thumb;
                    }
                    &::-webkit-slider-thumb {
                        @include range-thumb;
                        -webkit-appearance: none;
                    }

                    @mixin range-track {
                        background: transparent; 
                        border-color: transparent;
                        color: transparent;
                        width: 100%;
                        cursor: pointer;
                        border: 0;
                        height: 12px;
                    }

                    &::-ms-track {
                        @include range-track;
                    }
                    &::-moz-range-track {
                        @include range-track;
                    }
                    &::-webkit-slider-runnable-track {
                        @include range-track;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            &--values {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0 2px 0 4px;

                &-value {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 4px;
                    width: auto;
                    font-size: 16px;

                    img {
                        width: 16px;
                    }
                }
            }
        }

        &__selected {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &--amount {
                display: flex;
                flex-direction: column;
                gap: 8px;
                border-radius: 10px;
                border: 1px solid var(--trashie-ui-faded-black-60);
                padding: 16px;

                &-label {
                    color: var(--trashie-ui-faded-black-60);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 120%;
                }

                &-value {
                    color: var(--trashie-ui-faded-black-100);
                    font-size: 40px;
                    font-weight: 300;
                    line-height: 100%
                }
            }

            &--balance {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                &-value {
                    color: var(--trashie-ui-faded-black-60);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 100%;
                }
            }
        }

        &__note {
            color: var(--trashie-ui-faded-black-60);
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }

        &__summary {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;
            border-radius: 20px;
            background: var(--trashie-ui-faded-black-10);

            &--title {
                font-family: "A2RecordGothicCondensed";
                color: var(--trashie-ui-faded-black-100);
                font-size: 20px;
                font-weight: 700;
                line-height: 100%;
                padding-bottom: 16px;
                border-bottom: 1px solid var(--trashie-ui-faded-black-30);
            }

            &--order {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                &-label,
                &-value {
                    color: var(--trashie-ui-faded-black-60);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 100%;
                }

                &-label {
                    flex: 1;
                }
            }

            &--applied {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;

                &-label,
                &-value {
                    color: var(--trashie-ui-faded-black-100);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 100%;
                }

                &-label {
                    flex: 1;
                }
            }

            &--total {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                padding-top: 16px;
                border-top: 1px solid var(--trashie-ui-faded-black-30);

                &-label,
                &-value {
                    color: var(--trashie-ui-faded-black-60);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 100%;
                }

                &-label {
                    flex: 1;
                }
            }

            &--icon {
                height: 19px;
            }
        }

        &__conditions {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-bottom: 8px;

            &--item {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                gap: 8px;

                &-icon {
                    margin: 2px 0;
                    width: 20px;
                }

                &-label {
                    color: var(--trashie-ui-faded-black-60);
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 120%;

                    a {
                        text-decoration: underline;
                    }
                }
            }
        }

        &__terms {
            color: var(--trashie-ui-faded-black-60);
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            margin: 0;

            input {
                border-color: var(--trashie-ui-faded-black-100);
            }

            a {
                color: var(--trashie-ui-faded-black-100);
            }
        }

        &__cta {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__back,
        &__submit {
            border: 0;
            border-radius: 50px;
            padding: 16px 32px;
            text-align: center;
            font-size: 18px;
            font-weight: 300;
            line-height: 90%;
            animation: none;
        }

        &__back {
            background: var(--ui-crisp-white);
            color: var(--trashie-ui-faded-black-100);
            border: 1px solid var(--trashie-ui-faded-black-100);
        }

        &__submit {
            background: var(--trashie-ui-faded-black-100);
            color: var(--ui-crisp-white);

            &:disabled {
                opacity: 0.4; 
            }
        }
    }

    .ShopifyCheckout__footer {
        background: var(--trashie-ui-faded-black-10);
        padding: 16px 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        color: var(--trashie-ui-faded-black-100);
        font-size: 16px;
        font-weight: 400;
        line-height: 115%;

        &--info {
            display: flex;
            flex-direction: column;
            gap: 0;
            width: 100%;
            overflow: hidden;

            &-label,
            &-email {
                margin: 0;
            }

            &-email {
                color: var(--trashie-ui-faded-black-60);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }

        &--switch {
            display: flex;
            padding: 0;
            margin: 0 0 0 6px;
            border: 0;
            text-decoration: underline;
            white-space: nowrap;
        }
    }
}
