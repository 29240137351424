@import '../../../../scss/_trashie.scss';

.Rewards {
  &__main {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;
    background: var(--trashie-ui-faded-black-10);

    &--container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;
      max-width: 1312px;

      font-family: GT Maru;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      padding: 112px 0;
    }

    &--load-more {
      width: fit-content;
      margin: 40px auto 0;
    }

    .noData {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 40px;
      padding: 48px 0;
      gap: 32px;

      &__image {
        max-height: 150px;
      }

      &__message {
          max-width: 840px;
          font-size: 24px;
          font-weight: 700;
          line-height: 140%;
          text-align: center;
          text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .Rewards {
    &__main {
      padding: 0;

      &--container {
        padding: 64px 20px;

        &--titleSection {
          flex-direction: column;
          align-items: center;
          text-align: left;
          margin-bottom: 0;

          &--contentText {
            width: 80%;
            align-items: center;

            &--subtitle {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
