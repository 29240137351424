.CollapsableSection {
    width: 100%;

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        border: 0;
        background-color: unset;
    }

    &__icon {
        height: 20px;
        width: 20px;
        margin: 0 8px;

        &.large {
            height: 26px;
            width: 26px;
        }
    }

    &__content {
        overflow: hidden;
        transition: all 400ms ease-in-out;
    }
}
