.ImpactMetrics {
    display: flex;
    padding: 24px 16px;
    align-self: stretch;
    width: 100%;
    justify-content: center;
    padding: 32px 16px;
    margin-bottom: 32px;


    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
        max-width: 1312px;
        width: 100%;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            width: 100%;
            text-transform: none;
        }

        &--content {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 32px;

            &--item {
                display: flex;
                padding: 4px 12px;
                flex-direction: row;
                align-items: flex-start;
                align-self: stretch;
                justify-content: space-between;
                border-radius: 8px;
                border: 1px solid var(--trashie-ui-faded-black-20);
                background: var(--trashie-ui-crisp-white);
                box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
                max-width: 416px;
                width: 100%;

                &--title {
                    display: flex;
                    padding: 8px 0px;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;
                }

                &--value {
                    display: flex;
                    padding: 8px 0px;
                    align-items: center;
                    gap: 16px;
                    align-self: stretch;

                    &--metric {
                        display: flex;
                        flex-direction: column;

                        &--number {
                            color: var(--trashie-ui-faded-black-100);
                            text-align: right;
                            font-family: "GT Maru";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 120%;
                        }

                        &--equivalence {
                            color: var(--trashie-ui-faded-black-50);
                            font-family: "GT Maru";
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            text-transform: none
                        }
                    }
                }
            }

            &--middleItem {
                @extend .ImpactMetrics__container--content--item;
                border-radius: 8px;
            }
            &--lastItem {
                @extend .ImpactMetrics__container--content--item;
                border-radius: 8px;
            }
        }

        &--cta {
            width: 100%;
            display: flex;
            justify-content: left;

            &--button {
                max-width: 238px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .ImpactMetrics {
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            align-self: stretch;

            &--title {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                width: 100%;
            }

            &--content {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 0px;

                &--item {
                    display: flex;
                    max-width: 100%;
                    padding: 4px 12px;
                    flex-direction: row;
                    align-items: flex-start;
                    align-self: stretch;
                    justify-content: space-between;
                    border: 1px solid var(--trashie-ui-faded-black-20);
                    background: var(--trashie-ui-crisp-white);
                    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
                    border-radius: 8px 8px 0px 0px;

                    &--title {
                        display: flex;
                        padding: 8px 0px;
                        align-items: center;
                        gap: 16px;
                        align-self: stretch;
                    }

                    &--value {
                        display: flex;
                        padding: 8px 0px;
                        align-items: center;
                        gap: 16px;
                        align-self: stretch;
                    }
                }

                &--middleItem {
                    @extend .ImpactMetrics__container--content--item;
                    border-radius: 0px;
                }

                &--lastItem {
                    @extend .ImpactMetrics__container--content--item;
                    border-radius: 0px 0px 8px 8px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .ImpactMetrics {
        &__container {
            &--cta {
                width: 100%;

                &--button {
                    max-width: 100%;
                }
            }
        }
    }
}
