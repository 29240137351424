.container{
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: var(--trashie-ui-faded-black-60);
    font-family: GT Maru;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: none;
}

@media screen and (max-width: 1020px) {
    .container{
        font-size: 14px;
    }
}
