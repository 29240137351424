@import '../../../../../scss/trashie';

.container {
    display: flex;
    flex-direction: row;
    /* height: 128px; */
    padding: 16px 16px 16px 24px;
    align-items: center;
    gap: 24px;
    border-radius: 20px;
    background: var(--trashie-ui-crisp-white);
    justify-content: space-between;

    .dataContainer {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        width: 100%;
    }

    .actionContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 24px;

        .infoContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            flex: 1 0 0;
            width: 394px;

            .datesStyle {
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-transform: none;
            }

            .deliveredDateContainer {
                @extend .datesStyle;
                color: var(--take-back-bags-lime);
            }

            .shippedDateContainer {
                @extend .datesStyle;
                color: var(--trashie-ui-faded-black-60);
            }

            .shippedExpiredDateContainer {
                @extend .datesStyle;
                color: var(--trashie-primary-rich-red);
            }
        }

        .button {
            width: 218px;
            font-size: 14px;
            padding: 16px;
            max-height: 53px;
        }
    }



    .imageStyle {
        width: 90px;
        max-height: 91px;
        flex-shrink: 0;
    }

    .titleStyle {
        color: var(--ui-faded-black);
        font-family: GT Maru;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: uppercase;
    }

    .subtitleStyle {
        color: var(--trashie-ui-faded-black-60);
        font-family: GT Maru;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
    }

    .statusStyle {
        color: var(--trashie-ui-faded-black-60);
        font-family: GT Maru;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-decoration: underline;
    }

    .statusInfo {
        display: flex;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        padding: 10px 12px 12px 12px;
        gap: 8px;
        align-items: flex-start;

        .actionContainer {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;

            .infoContainer {
                gap: 4px;
                justify-content: center;
                width: 100%;

                .datesStyle {
                    font-size: 12px;
                }
            }

            .button {
                height: 34px;
                width: 100%;
            }
        }

        .dataContainer {
            align-items: flex-start;
            gap: 16px;

            .imageContainer {
                max-height: 110px;
                height: 100%;

                .imageStyle {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .titleStyle {
            font-size: 16px;
        }

        .subtitleStyle {
            font-size: 12px;
        }

        .statusStyle {
            font-size: 12px;
        }
    }

}
