.donut{
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.donut:after{
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.donut > span{
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.donut .donut-left{
  left: 0;
}
.donut .donut-bar{
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.donut .donut-left .donut-bar{
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.donut .donut-right{
  right: 0;
}
.donut .donut-right .donut-bar{
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.donut .donut-value{
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #ffffff;
  font-size: 24px;
  color: black;
  font-weight: bold;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}
.donut.blue .donut-bar{
  border-color: #E4AB26;
}
.donut.blue .donut-left .donut-bar{
  animation: loading-2 1.5s linear forwards 1.8s;
}






@keyframes loading-1{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
  }
}
@keyframes loading-2{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(144deg);
      transform: rotate(144deg);
  }
}
@keyframes loading-3{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
  }
}
@keyframes loading-4{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(36deg);
      transform: rotate(36deg);
  }
}
@keyframes loading-5{
  0%{
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100%{
      -webkit-transform: rotate(126deg);
      transform: rotate(126deg);
  }
}
@media only screen and (max-width: 990px){
  .donut{ margin-bottom: 20px; }
}