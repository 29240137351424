@import '../../../../../scss/trashie.scss';

.title {
  align-self: stretch;
  color: var(--trashie-ui-faded-black-100);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
}

.loader {
  align-self: center;
}

.balanceContainer {
  max-width: 434px;
  padding: 0 24px;
}

.trashieExtensionContainer {
  padding-left: 12px;
  background-color: #1B1A1B;
  height: 53px;
  width: 100%;
  display: flex;
  align-items: center;
}

.trashieExtensionTitle {
  color: #FCFFFD;
  font-family: GT Maru;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.trashieExtensionDescription {
  color: #6F706F;
  font-family: GT Maru;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: '13%';
}

.descriptions {
  align-self: stretch;
  color: var(--trashie-ui-faded-black-100);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.copyCodeButton {
  border-radius: 10px;
  border: 1px solid var(--trashie-ui-faded-black-100);
  background: var(--trashie-ui-crisp-white);
}

.dashboardButton {
  display: flex;
  flex-flow: row;
  border-radius: 14px;
  border: 1px solid var(--trashie-ui-faded-black-100);
  background: var(--take-back-bags-banana-runtz);
  padding: 16px 32px;
  align-items: center;
  @include ctaPrimary;
  @include ctaLarge;
}

.dashboardButtonArrow {
  width: '24px';
  height: '24px';
}

.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
}

.carouselItem {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.arrowRight {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  transform: rotate(90deg);
  color: black;
}

.arrowLeft {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  transform: rotate(270deg);
  color: black;
}

.rewardPageWrapper {
  background-color: white;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: GT Maru;
}


.extensionButton {
  display: flex;
  width: 56px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid var(--trashie-ui-faded-black-100);
  background: var(--trashie-ui-faded-black-30);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  text-transform: uppercase;
  color: var(--trashie-ui-faded-black-100);
}

.extensionIcon {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 7.241px 9.19px 7.243px 9.281px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--gradient-light-mint);
}

.balance {
  color: var(--trashie-ui-faded-black-100);
  text-align: right;
  font-family: "GT Maru Mono";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
  margin-left: auto;
}

.offerCarousel {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--trashie-ui-crisp-white);
  width: 300px;
  padding: 16px;
}

.cardIconContainer {
  padding-top: 10px;
  max-width: 210px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  float: left;
}

.cardIcon {
  width: auto;
  height: 100%;
  overflow: hidden;
  display: block;
}

.cardBalanceContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 60px;
}

.cardTopSection {
  display: flex;
  flex-flow: row;
}

.cardBottomSection {
  margin-top: 74px;
}

.cardBottomSectionTrashie{
  @extend .cardBottomSection;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  img{
    transform: rotate(-90deg);
    width: 22px;
    margin-right: 1px;
    margin-bottom: 1.2rem;
  }
}
.cardContainer {
  display: flex;
  gap: 16px;
  width: 100%;
  height: 204px;
  padding: 16px;
  justify-content: 'space-between';
  align-items: 'flex-start';
  border-radius: 11px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
  background: var(--gradient-light-dream-pop-pink);
}

.cardDescription {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
  margin-bottom: 1%;
  font-family: 'GT Maru'; 
}

.cardUserName {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
}

.viewAllButton {
  color: var(--trashie-ui-faded-black-100);
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  text-decoration-line: underline;
  text-transform: uppercase;
  background-color: white;
  border: 0px solid white !important;
}

.contentContainer {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.carouselContainer {
  width: 360px;
  height: 484px;
}

.spendSection {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 40px;
  background: var(--trashie-ui-faded-black-10);
  padding-bottom: 200px;
}

.productText {
  color: var(--trashie-ui-faded-black-100);
  font-size: 18px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
}

.productTitle {
  @extend .productText;
  padding-top: 10px;
  font-weight: 500;
}

.productDescription {
  @extend .productText;
  font-weight: 400;
}

.shopNowButton {
  @include ctaPrimary;
  @include ctaLarge;
  width: 100% !important;

  padding: 16px 32px;
  margin-bottom: 56px;
  max-width: 300px;
  gap: 8px;
  align-self: center;
  color: var(--trashie-ui-faded-black-100);
  border-radius: 14px;
  border: 1px solid var(--trashie-ui-faded-black-100);
  background: var(--trashie-ui-faded-black-100);
}

.couponContainer{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .couponTittle{
    @extend .descriptions;
    margin-bottom: 0;
    text-align: left;
  }

  .couponDescription{
    @extend .descriptions;
    margin-bottom: 0;
    text-align: center;
  }
  
}

.couponSection{
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ctaLink{
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

@media only screen and (max-width:480px) {

  .cardContainer{
    width: 100%;
    height: 200px;
  }

  .cardBottomSection {
    margin-top: 74px;
  }
  .title{
    padding:0 16px;
  }
}

.informativeBannerLearnMoreText {
    padding-top: 8px;
    padding-right: 32px;
    text-align: right;
    text-decoration: underline;
    width: 250px;
    height: 37px;
    border-radius: 10px;
    margin-left: auto;
}

.informativeBannerArrow {
    margin-left: 0px;
    border: 0.5px solid #292829;
    height: 20px;
    width: 20px;
    padding: 5px;
    border-radius: 40px;
    background-color: #FFFB5D;
    transform: rotate(270deg);
}

.informativeBannerButton {
    @include ctaPrimary;
    @include ctaMedium;
    width: 179px;
    height: 37px;
    margin-right: 80px;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        background-color: #FFFB5D;
        border-color: black;
    }
}

@media only screen and (max-width:1020px) {
    .informativeBannerLearnMoreText {
      width: 100px;
    }
}