@import '../../../../scss/_trashie.scss';

.Slider {
    .splide__pagination__page {
        background: #8D8D8D;

        &.is-active {
            background: #1b1a1b;
            transform: scale(1);
        }
    }

    .splide__arrows {
        &.splide__arrows--ltr {
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            justify-content: flex-end;
            display: flex;
        }

        .splide__arrow {
            background: transparent;
            border: 1px solid #1b1a1b;
            width: 3rem;
            height: 3rem;
            opacity: 1;
            padding: 0;
            position: static;
    

            &.splide__arrow--prev {
                background-image: url("../../images/arrow-left-icon.svg");
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: 1rem 1rem;   
            }

            &.splide__arrow--next {
                background-image: url("../../images/arrow-right-icon.svg");
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: 1rem 1rem;
            }
        }
    }
}
