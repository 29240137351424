.main {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 650px;

    .container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}
