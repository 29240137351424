.TakeBackBagDetails {
    display: flex;
    width: 100%;
    padding: 64px;
    background: var(--trashie-ui-faded-black-10);

    &__container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;
        &--content{
            max-width: 800px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 1020px) {
    .TakeBackBagDetails {
        padding: 16px;
    }
}
