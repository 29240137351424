.inputClassContainer {
    display: flex;
    width: 100%;

    .formItem {
        width: 100%;
        padding: 12px 0;
    
        .formItemLabel {
            color: var(--ui-faded-black);
            font-family: GT Maru;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 10px;
        }

        .formItemElement {
            width: 100%;
            padding: 12px;
            border: 1px solid var(--ui-key-line-gray);
            box-sizing: border-box;
            border-radius: 10px;
            border: 1px solid var(--trashie-ui-faded-black-100);
            background: var(--trashie-ui-faded-black-10);

            &.active {
                border: 1px solid var(--ui-faded-black);
                box-shadow: inset 0 0 0 1px var(--ui-faded-black);
            }

            &.error {
                border: 1px solid var(--japan-color-dry-red);
                box-shadow: inset 0 0 0 1px var(--japan-color-dry-red);
    
                .formItemInput {
                    color: var(--japan-color-dry-red) !important;
                }
            }

            .formItemInput {
                width: 100%;
                border: 0;
                background: var(--ui-crisp-white);

                color: var(--ui-faded-black);
                font-family: GT Maru;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                text-transform: uppercase;
                padding: 0;
                background: var(--trashie-ui-faded-black-10);

                &.password {
                    text-transform: none;
                }

                &::placeholder {
                    color: var(--ui-key-line-gray) !important;
                }

                &:active,
                &:focus,
                &:focus-visible {
                    border: 0 !important;
                    box-shadow: none !important;
                    padding: 0;
                    outline: 0;
                }
            }
        }

        .formItemError {
            color: var(--japan-color-dry-red);
            background-color: var(--trashie-ui-error-background);
            text-align: center;
            font-family: GT Maru;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: start;
            margin: 16px 0 0;
            padding: 8px;
            border-radius: 4px;
        }
    }    
}

@media screen and (max-width: 1020px) {
    .inputClassContainer {
        .formItem {    
            .formItemLabel {
                font-size: 16px;
            }

            .formItemElement {
                .formItemInput {
                    font-size: 16px;
                }
            }
        }
    }
}
