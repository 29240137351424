.MaintenanceBanner {
    height: 18px;

    &__content {
        display: flex;
        flex-direction: column;
        z-index: 9999;
        position: fixed;
        width: 100%;
        overflow: hidden;
        background: #e4ab27;
        border: 1px solid black;
        &--dismiss{
            display: flex;
            justify-content: flex-end;
            margin-right: 30px;
            cursor: pointer;
        }
        &--message{
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;   
            margin: 10px;
            color: var(--trashie-ui-faded-black-100);
        }
    }

    &__text {
        display: inline-block;
        white-space: nowrap;
        font-size: 12px;
        color: white;
        text-transform: uppercase;
        font-family: 'Gt Maru';
        font-weight: 500;
    }
}

@media screen and (max-width: 1020px) {
    .MaintenanceBanner {
        height: 15px;

        &__content {
            gap: 20px;
        }

        &__text {
            font-size: 10px; 
        }
    }
}
