@import '../../../../../scss/trashie';

.state-select-container {
    display: flex;
    padding: 0px;
    max-width: 100%;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--trashie-ui-crisp-white, #FCFFFD);
}

.state-select {
    background: var(--trashie-ui-crisp-white, #FCFFFD);
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    font-size: 18px !important;
}

.state-select-option {
    font-family: GT Maru !important;
}
