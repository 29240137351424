.facebook-button {
  color: #ffffff;
  background: #1877F2;
  border-color: #1877F2;
}

.facebook-button:hover {
  color: #ffffff !important;
  background: #2d88ff !important;
  border-color: #2d88ff !important;
}