.DotsLoader {
    display: flex;
    justify-content: center;
    gap: 8px;
    width: 100%;
    margin: 4.5px auto;

    @keyframes loader {
        0% {
            opacity: 0;
        }
    
        100% {
            opacity: 1;
        }
    }

    .dot {
        background-color: var(--trashie-ui-crisp-white);
        border-radius: 50%;
        width: 8px;
        height: 8px;
        animation: loader 2s infinite;

        &:nth-child(1) {
            animation-delay: 0.4s;
        }

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0s;
        }
    }
}
