.CompoundRadioOption {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 12px 20px;
    margin: 0;
    gap: 20px;
    align-items: center;
    border-radius: 14px;
    border: 1px solid var(--trashie-ui-faded-black-30);
    background: var(--trashie-ui-crisp-white);
    color: var(--trashie-ui-faded-black-100);

    p {
        margin: 0;
    }

    a {
        text-decoration: underline;
    }

    &__option {
        display: flex;
        align-content: center;

        &--icon {
            height: 18px;
        }

        &--input {
            appearance: none;
            height: 18px;
            width: 18px;
            border: 1px solid var(--trashie-ui-faded-black-100);
            border-radius: 50%;
            background-color: transparent;
            outline: none;
            cursor: pointer;

            &:not(:disabled):checked {
                background-clip: content-box;
                background-color:  var(--trashie-primary-code-blue);
                padding: 3px;
            }

            &:disabled {
                border: 1px solid var(--trashie-ui-faded-black-60);
            }
        }
    }

    &__label {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 8px;
        align-items: flex-start;

        &--heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &-title {
                font-size: 18px;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
                text-align: left;
            }

            &-subtitle {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 6px;

                font-size: 12px;
                font-weight: 700;
                line-height: 150%;
                text-transform: uppercase;
                margin: 0 8px;

                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 3px;
                    width: 100%;
                    min-width: 49px;
                }

                img {
                    height: 14px;
                }
            }
        }

        &--subheading {
            font-size: 11px;
            font-weight: 400;
            line-height: 150%;
            color: var(--trashie-ui-faded-black-60);
            text-align: left;
        }
    }

    &.active {
        border: 1px solid var(--trashie-ui-faded-black-100);
    }

    &.locked,
    &.forbidden {
        border: 1px solid var(--trashie-ui-faded-black-20);
        color: var(--trashie-ui-faded-black-60);

        img {
            opacity: 0.6;
        }
    }
}

@media screen and (max-width: 1020px) {
    .CompoundRadioOption {
        padding: 12px 16px;
        gap: 12px;
    }
}
