.ShippingProviderLocations {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;

    &__loader {
        background-color: var(--trashie-primary-code-blue) !important;
    }

    &__desc {
        color: var(--trashie-ui-faded-black-100);
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
    }
}

.ShippingProviderLocationsItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    padding-bottom: 12px;

    &:not(:last-child) {
        border-bottom: 1px solid var(--trashie-ui-faded-black-30);
    }

    &__desc {
        display: flex;
        flex-direction: column;
        flex: 1;

        h4 {
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            color: var(--trashie-ui-faded-black-100);
        }

        p,
        span {
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
            color: var(--trashie-ui-faded-black-100);
            margin: 0;
        }

        &-more {
            margin: 4px 0 !important;

            span {
                font-size: 12px;
            }

            button {
                border: 0;
                background: none;
                font-size: 12px;
                text-decoration: underline;
            }

            img {
                width: 8px;
            }
        }

        &-schedule {
            display: flex;
            flex-direction: column;
            max-height: 200px;
            overflow: hidden;
            transition: all 0.5s ease-in-out;

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 150%;
                color: var(--trashie-ui-faded-black-100);
                margin: 0;
            }

            &.collapsed {
                max-height: 0;
            }
        }
    }

    &__distance {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;

        img {
            height: 24px;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 150%;
            color: var(--trashie-ui-faded-black-100);
            margin: 0;
        }
    }
}
