@import '../../../../scss/trashie';

.TrashieContainerPublic {
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  font-family: Gt Maru, sans-serif;
  font-weight: 500;
  background: var(--trashie-primary-thrifty-blue);

  &__content {
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--trashie-ui-faded-black-10);

    &--left {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      height: 100%;
      background: var(--trashie-primary-thrifty-blue);
    }

    &--right {
      display: flex;
      width: 540px;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .TrashieContainerPublic {
    &__content {
      &--right {
        width: 480px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .TrashieContainerPublic {
    &__content {
      flex-direction: column;

      &--left {
        flex: unset;
        width: 100%;
        height: 258px;
        overflow: hidden;
      }

      &--right {
        flex: 1;
        width: 100%;
      }
    }
  }
}
