@import '../../../../scss/_trashie.scss';

.MyRewards {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 350px;
    background: var(--trashie-ui-faded-black-10);

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 960px;
        padding: 100px 0px;

        &--container {
            width: 100%;
        }

        &--loader {
            margin: 80px auto;
        }
        &--section{
            margin-top: 32px;
        }
    }

    &__backButton {
        max-width: 72px;
    }
}

@media screen and (max-width: 768px) {
    .MyRewards {
        &__content {
            padding: 32px 16px;
        }
    }
}
