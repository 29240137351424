.DashboardNew {
    display: flex;
    width: 100%;
    padding: 0 48px;
    flex-direction: column;
    background: var(--trashie-ui-faded-black-10) url('../../images/dashboard-rainbow.png') no-repeat right top / 320px; 

    &__container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-flow: column;
    }
}

@media screen and (max-width: 1020px) {
    .DashboardNew {
        padding: 0;
        background: none;
    }
}
