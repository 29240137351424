.GlobalLogin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 6rem 64px;

    &.isEmbedded {
        padding: 0 !important;
    }

    &__logo,
    &__navigation {
        margin-bottom: 48px;
    }

    &__logo {
        vertical-align: middle;
        display: inline-block;
        height: 40px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        &--header {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            width: 100%;

            .subtitle,
            .text {
                text-transform: none;
                text-align: left;
                color: var(--trashie-ui-faded-black-100);
                width: 100%;
                margin-bottom: 0;
            }

            .subtitle {
                font-size: 20px;
                font-weight: 700;
                line-height: 140%;
            }

            .text {
                font-size: 14px;
                font-weight: 400;
            }
        }

        &--label {
            margin: 0;
        }

        &--form {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            width: 100%;
        }

        &--option {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: auto;
            padding-top: 16px;

            &-text {
                font-size: 14px;
                font-weight: 400;
                margin: 0;
                text-transform: none;
                color: var(--trashie-ui-faded-black-100);
            }

            &-link {
                width: fit-content;
                padding: 12px;
            }
        }

        &--dotsLoader {
            background-color: var(--trashie-primary-code-blue) !important;
            margin-top: 16px;
        }

        &--loader {
            display: flex;
            width: 100%;
        }
    }
}

@media screen and (max-width: 1440px) {
    .GlobalLogin {
        padding: 6rem 48px;
    }
}

@media screen and (max-width: 1200px) {
    .GlobalLogin {
        max-width: 480px;
        padding: 16px 16px 32px;

        &__logo {
            &.hidden {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 520px) {
    .GlobalLogin {
        max-width: 100%;
    }
}
