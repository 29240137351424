@import '../../../../scss/trashie.scss';

.RegisterMaxWidth {
  max-width: 434px;
}

.tabs {
  display: none;
}

.banner {
  color: var(--ui-faded-black);
  font-size: 90px;
  font-style: normal;
  font-weight: 900;
  line-height: 80%;
  text-transform: uppercase;
  font-family: A2RecordGothicXCondensed;
  background: var(--japan-color-hard-mint);
  border-radius: 14px;
  text-align: center;
  padding: 16px 17px;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.firstLegend {
  color: var(--ui-faded-black);
  font-family: GT Maru;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 24px;
}

.stepTitle {
  font-family: GT Maru;
  color: var(--trashie-ui-faded-black-100);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
}

.stepDescription {
  font-family: GT Maru;
  color: var(--trashie-ui-faded-black-100);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.butonTrashieBase {
  @include ctaLarge;
  width: 201px !important;

  &:hover {
    @include ctaLarge;
  }
}

.buttonContainer {
  margin-top: 24px;
  @extend .RegisterMaxWidth;

  .button {
    border-radius: 14px;
    border: 1px solid var(--trashie-ui-faded-black-100);
    background: var(--take-back-bags-banana-runtz);
    font-weight: 600;
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'GT Maru';
    font-size: 14px;
    text-transform: uppercase;

    img {
      fill: white !important;
    }
  }
}

.buttonTrashie {
  @extend .butonTrashieBase;
  @include ctaPrimary;

  &:hover {
    @include ctaPrimary;
  }

  &:disabled {
    @include ctaSecondary;
    background: var(--ui-crisp-white) !important;
    color: var(--ui-faded-black) !important;
  }
}

.buttonEdit {
  @extend .butonTrashieBase;
  @include ctaSecondary;
  width: 84px !important;

  &:hover {
    @include ctaSecondary;
  }
}

.buttonContainer {
  display: flex;
  padding: 0px 24px 16px;
  justify-content: center;
}

.inputEmail {
  text-transform: uppercase;
  margin-top: 8px;
}

.inputEmailError {
  text-transform: uppercase;
  border-radius: 10px;
  border: 2px solid var(--japan-color-dry-red) !important;
  background: var(--ui-crisp-white) !important;
}

.emailErrorText {
  color: var(--japan-color-dry-red);
  font-family: GT Maru;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.addressVerificationLegend {
  color: var(--ui-faded-black);
  font-family: GT Maru;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.emailLegend {
  color: var(--ui-faded-black);
  font-family: GT Maru;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.emailLengendContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.header {
  background: var(--ui-crisp-white);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.09));

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
    @extend .RegisterMaxWidth;

    img {
      height: 28px !important;
      max-width: 90px;
    }
  }
}

.separator {
  height: 2px;
  background: #E2E2E2;
  margin: 12px 0;
}

.message {
  color: var(--ui-faded-black);
  font-family: GT Maru;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: justify;
  margin: 24px 0 0;
}

.centeredContainer {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px
}

.qrImage {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.qrCard {
  width: 205.618px;
  height: 205.618px;
}

.buttonArrow {
  width: '24px';
  height: '24px';
}

.printLabel {
  padding: 8px 24px;
  border-radius: 10px;
  border: 1px solid var(--trashie-ui-faded-black-100, #1B1A1B);
  background: var(--trashie-ui-crisp-white, #FCFFFD);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
}

.qrButton {
  padding-left: 12px;
  padding-bottom: 4px;
}

.supportText {
  text-decoration-line: underline;
}

.coupon {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  border-radius: 14px;
  border: 2px solid #000;
  background-color: var(--japan-color-flat-sky);
  width: 360px;
  height: 200px;
  margin: 24px auto 10px;
  padding: 16px !important;

  .couponHeader {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    .couponLogo {
      max-height: 40px;
      max-width: 120px;
    }

    .couponValue {
      display: none;
      border-radius: 10px;
      border: 2px solid var(--ui-faded-black);
      background: var(--ui-crisp-white);
      padding: 8px;

      img {
        height: 29px;
      }
    }
  }

  .couponContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    .couponMessage {
      color: var(--ui-faded-black);
      font-family: GT Maru;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      text-transform: uppercase;
      margin: 0;
    }

    .couponCode {
      color: var(--ui-faded-black);
      font-family: GT Maru;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 150%;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .trashieCouponContainer {
    @extend .couponContent;
    text-align: left;
  }
}

.copyButton {
  color: var(--ui-faded-black);
  text-align: center;
  font-family: GT Maru;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border: 0;
  background: none !important;
  margin: 0 auto;

  .copyCode {
    width: 24px;
    height: 24px;
    margin-left: 16px;
  }
}

.pinLegend {
  color: var(--ui-faded-black);
  font-family: GT Maru;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
}

.pinLegendSecond {
  color: var(--ui-faded-black);
  font-family: GT Maru;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.pinlegendThird {
  @extend .pinLegendSecond;
  margin-top: 10px;
  margin-bottom: 8px;
}

.buttonContainerEdit {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100%;
  max-width: inherit;
  background: var(--trashie-ui-faded-black-10);

  &.rainbowBg {
    background: var(--trashie-ui-faded-black-10) url('../../images/rainbow-bg.png') no-repeat left top;
  }
}

.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.progressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @extend .RegisterMaxWidth;
}

.contentContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  padding: 48px 16px 0;

  @extend .RegisterMaxWidth;

  // bootstrap .tab-content
  > div {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
  }
}

.expirationDateContainer {
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  gap: 5px;

  .expirationDate {
    color: var(--trashie-ui-faded-black-100);
    font-family: "GT Maru";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .expirationDateTitle{
    color: var(--trashie-ui-faded-black-100);
    font-family: "GT Maru";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
}

.footer{
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 32px;
  width: 100%;
  @extend .RegisterMaxWidth;

  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  color: var(--trashie-ui-faded-black-50);
  margin-top: auto;
  gap: 3px;

  a {
    color: var(--trashie-ui-faded-black-50);
    text-decoration: underline;
  }
}

@media only screen and (max-width:480px) {
  .separator {
    display: none;
  }

  .contentContainer {
    padding: 32px 16px 0;
  }

  .container {
    padding: 0 !important;

    .row {
      padding-left: 0;
      padding-right: 0 !important;
    }
  }

  .tabContent {
    padding: 0;
  }

  .firstLegend {
    margin-top: 16px;
  }

  .copyButton {
    margin-bottom: 48px;
  }

  .banner {
    font-size: 80px;
  }

  .header {
    width: 100%;
  }

  .buttonContainer {
    .button {
      width: 327px;
    }
  }
}

.infoContainer {
  max-width: 434px;
}

.addressSelectedContainer {
  border: 1px solid #A9A9A9;
  box-shadow: 0px 4px 15px 0px #00000017;
}

.addressCardContent {
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: row;
}

.addressSelectRadioButton {
  margin-left: auto;
  appearance: none;
  height: 18px;
  width: 18px;
  border: 1px solid var(--trashie-ui-faded-black-100);
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  &:not(:disabled):checked {
      background-clip: content-box;
      background-color:  var(--trashie-primary-code-blue);
      padding: 2px;
  }

  &:disabled {
      border: 1px solid var(--trashie-ui-faded-black-60);
  }
}

.addressSelectCardName {
  text-transform: uppercase;
  font-family: GT Maru;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}

.addressSelectCardAddress {
  padding: 8px 16px 16px 16px;
  font-family: GT Maru;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #6F706F;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
}

.addressSelectItemComponentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.addressSelectInfoSection {
  max-height: 110px;
  display: flex;
}

.addressSelectViewContainer {
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px
}

.addressSelectContinueButton {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bottomSectionContainer {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.bottomSectionTitle {
  font-family: GT Maru;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #292829;

  p {
    margin: 0;
  }
}

.bottomSectionCta {
  border: 0;
  background: none;
  color: var(--Trashie-Primary-Code-Blue, #33F);
  font-family: "GT Maru";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: uppercase;
}

.enterNewAddressLink {
  font-family: GT Maru;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #3333FF;

  &:hover {
    color: #3333FF;
  }
}
