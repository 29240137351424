.RewardItem {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .5s cubic-bezier(.165, .84, .44, 1);

    &:hover {
        transform: scale(1.025);
    }
}

// SIZE_XS
@media screen and (max-width: 576px) {
    .RewardItem {
        width: 100%;
    }
}