@import '../../../../scss/_trashie.scss';

.main {
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    font-family: Gt Maru, sans-serif;
    font-weight: 500;
    background-color: var(--trashie-primary-thrifty-blue);

    &.blank {
        background-color: var(--ui-crisp-white);
    }

    .content {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}
