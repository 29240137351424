.RewardsViewList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    padding: 0 16px 16px;

    // Hide scrollbars
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 1280px) {
    .RewardsViewList {
        gap: 16px;
    }
}
