.CopyButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border: 0;
    padding: 0;
    width: 100%;

    &__data {
        color: var(--trashie-primary-code-blue);
        font-family: Courier;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        text-transform: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
            color: var(--trashie-primary-code-blue);
        }
    }

    &__button {
        padding: 0;
        border: none;
        background-color: unset;

        img {
            width: 20px;
        }
    }

    &__copied {
        color: var(--trashie-ui-lime);
        font-size: 14px !important;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
        text-transform: capitalize;
    }
}
