.Offers {
    &__cta {
        text-decoration: underline;
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    &__noData {
        font-size: 24px;
        font-weight: 700;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
    }
}
