@import '../../../../scss/_trashie.scss';

.Settings {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 350px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 434px;
        margin: 65px 0;

        .container {
            padding: 0;
            width: 100%;

            .pane {
                display: flex;
                flex-direction: column;
                align-items: center;

                .intro {
                    color: var(--ui-faded-black);
                    text-align: center;
                    font-family: GT Maru;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    gap: 24px;

                    .form-item {
                        padding: 0;

                        p {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 150%;
                        }
                    }

                    .cta {
                        @include ctaPrimary;
                        @include ctaLarge;

                        width: 100%;
                        margin-top: 24px;
                    }
                }
            }
        }

        .options {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 24px;
    
            .option {
                color: var(--ui-faded-black);
                text-align: center;
                font-family: GT Maru;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                background-color: unset;
                text-transform: uppercase;
                text-decoration-line: underline;
                border: 0;
            }
        }
    }

    .loader {
        display: flex;
        flex-flow: column;
        align-items: center;
    }
}

@media screen and (max-width: 1020px) {
    .Settings {
        .content {
            margin: 40px 20px 65px;
        }

        .loader {
            margin: 16px;
        }
    }
}
