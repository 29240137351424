@import '../../../../scss/trashie';

.ProgressBar {
    height: 7px;
    background: var(--trashie-ui-faded-black-30);
    width: 100%;

    &__progress {
        height: 7px;
        background: var(--trashie-primary-code-blue);
    }
}
