.RewardsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    border-radius: 20px;
    align-items: center;
    gap: 64px 32px;
    justify-content: center;
}

// SIZE_SM
@media screen and (max-width: 767px) {
    .RewardsList {
        gap: 32px 16px;
    }

    .RewardsList-carousel {
        gap: 48px 16px;
        padding: 0;
        gap: 48px 16px;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 365px;
        margin-top: 32px;

        &.withControls {
            height: 450px;
        }

        .carousel {
            width: 100%;
            max-width: 311px;
            height: 100%;

            .carousel-inner {
                position: relative;
                width: 100%;
                overflow: hidden;
                height: 100%;
            }

            .carousel-control-prev,
            .carousel-control-next {
                top: unset;
                height: auto;
                bottom: 0;
                opacity: 1;
                width: auto;
            }

            .carousel-control-next {
                right: 0px;
            }

            .carousel-control-prev {
                right: 60px;
                left: auto;
            }

            .arrow-button {
                border-radius: 50%;
                border: 1px solid var(--trashie-ui-faded-black-100);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;

                svg {
                    width: 24px;
                    height: 24px;
                    margin: 0;
                    fill: var(--trashie-ui-faded-black-100);
                }
            }
        }

        .slider-footer-section {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 14px;

            .custom-indicators,
            .slide-counter {
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: 0;
            }

            .slide-counter {
                color: var(--trashie-ui-faded-black-60);
                font-family: GT Maru;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
            }

            .custom-indicator {
                width: 8px;
                height: 8px;
                background-color: var(--trashie-ui-faded-black-50);
                border-radius: 50%;
                margin-right: 8px;
            }

            .custom-indicator.active {
                background-color: var(--trashie-ui-faded-black-100);
            }
        }
    }
}
