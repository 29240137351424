@import '../../../../../scss/_trashie.scss';

.main {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 14px;
    background: var(--trashie-ui-crisp-white);
    width: 243.2px;

    .imgContainer {
        border-radius: 50%;
        background: none;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border:  0.625px solid var(--trashie-ui-faded-black-100);

        img {
            max-width: 100%;
            height: 100%;
        }
    }

    .offerDetails {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        width: 100%;

        .brand {
            color: var(--trashie-ui-faded-black-100);
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            text-transform: uppercase;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-transform: capitalize;
        }

        .cashBackPercentage {
            color: var(--trashie-ui-faded-black-100);
            text-align: center;
            font-size: 22px;
            font-weight: 500;
            line-height: 150%;
            text-transform: uppercase;
        }

        .activateButton {
            @include ctaPrimary;
            @include ctaMedium;
            @include ctaOffer;
            .chevron{
                transform: rotate(90deg);
                width: 18px;
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .main {
        width: 163.5px;
        padding: 16px 8px;
        gap: 8px;

        .imgContainer {
            height: 75px;
            width: 75px;
        }
    
        .offerDetails {
            .cashBackPercentage {
                font-size: 16px;
            }
    
            .activateButton {
                margin-top: 12px;
                font-size: 9px;
                padding: 8px 8px !important;
                height: 30px;
                .imageButton{
                    height: 12px;
                }
                .chevron{
                    display: none;
                }
            }

            .brand{
                font-size: 14px;
            }
        }
    }
}
