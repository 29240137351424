@import '../../../../scss/_trashie.scss';

.Reward {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 350px;
    background: var(--trashie-ui-faded-black-10);

    .stickyButton{
        display: none;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 1020px;
        padding: 64px 16px;

        &--container {
            width: 100%;
        }

        &--loader {
            margin: 80px auto;
        }

        &--button {
            width: fit-content;
            color: var(--trashie-ui-faded-black-60);
            font-family: "GT Maru";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: uppercase;
            display: flex;
            gap: 4px;
            align-items: center;
            align-content: center;
            border: 0;
            background-color: unset;

            &:hover{
                color: var(--trashie-ui-faded-black-60);
            }
        }

        &--section{
            margin-top: 16px;
            background: var(--ui-crisp-white);
            border-radius: 20px;
            overflow: hidden;
        }
    }
}

@media screen and (max-width: 1020px) {
    .Reward {
        .stickyButton{
            display: flex;
        }
        &__content {
            padding: 32px 16px;
        }
    }
}
