.main {
    z-index: 200;
    align-items: center;
    display: flex;

    .container {
        width: 100%;
        height: 100%;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-end;

        .menu {
            vertical-align: middle;
            display: flex;
            height: 26px;
            padding: 0;
        }
    }
}

@media screen and (max-width: 1020px) {
    .main {
        .container {
            .wave, .menu {
                height: 26px;
            }

            .message {
                display: none;
            }
        }
    }
}
