@import '../../../../scss/_trashie.scss';

.GeneralModal {
    max-width: 467px;
    font-family: GT Maru !important;
  
    &__Content.modal-content {
      border-radius: 20px;
      background:  var(--trashie-ui-crisp-white) !important;
      border: none !important;
      overflow: hidden;
    }
  
    &__Body.modal-body  {
      padding: 0;
    }
}

.GeneralModalBackDrop {
    background: rgba(51, 51, 255, 1);
    backdrop-filter: blur(6px);
    --bs-backdrop-opacity: 1;
}

.GeneralModalContent {
    border-radius: 20px;
    background: var(--gradient-dream-pop-pink-gradient, linear-gradient(180deg, #FF9AF5 0%, #F87DFF 100%));
    display: flex;
    width: 640px;
    padding: 64px 32px 32px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    overflow: hidden;
}

.closeModalButtonHeader {
    position: absolute;
    padding: 0;
    right: 15px;
    top: 15px;
    height: 20px;
    width: 20px;

    .iconClose {
        width: 100%;
    }
}

.GeneralModalHeader {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: none;

    .GeneralModalTitle {
        width: 100%;
    }
}


.GeneralModal__Body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: none;
}

.GeneralModalFooter {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: none;
    border-top: none;
}

.saveModalButton {
    @include ctaPrimary;
    @include ctaLarge;

    max-width: 384px;
}

.cancelModalButton {
    @include ctaSecondary;
    @include ctaLarge;

    max-width: 384px;
}

@media (max-width: 1020px) {
    .modal-body {
        font-size: 18px;
    }
}

@media (max-width: 962px) {
    .modal-body {
        font-size: 16px;
    }
}

@media (max-width: 780px) {
    .modal-body {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .GeneralModal {
        .loading-modal {
            min-height: 500px;
        }
    }
}