.stickyButton {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-top: 1px solid var(--trashie-ui-faded-black-100);
    border-bottom: 1px solid var(--trashie-ui-faded-black-100);
    background: var(--trashie-ui-crisp-white);
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 1;

    &__container{
        display: flex;
        flex-direction: row;
        max-width: 768px;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        &--description{
            &--title{
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
            }

            &--subTitle{
                color: var(--trashie-ui-faded-black-60);
                font-family: "GT Maru";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;

                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    
        &--button{
            max-width: 210px;
            padding: 16px;
        }
    }
}
