.BrandCircle {
  .circle {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: none;
  }

  .circle-background {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: none;
    position: relative;
    top: -37.5px;
  }

  .circle-shadow {
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 1;
  }

  .circle-content {
    position: absolute;
    z-index: 3;
  }

  .circle-cover {
    width: 100px;
    height: 40px;
    position: absolute;
    z-index: 2;
    margin-top: 40px;
  }
}
