@import '../../../../../../scss/trashie';

.RegisterInvalid {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 32px;
    width: 100%;
    padding: 48px 40px 0;

    &__message {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 16px;

        &--logo {
            display: flex;
            max-width: 40px;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        &--title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            line-height: 140%;
            text-transform: uppercase;
            color: var(--trashie-ui-faded-black-100);
        }

        &--subtitle {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            line-height: 150%;
            color: var(--trashie-ui-faded-black-100);
        }
    }
}

@media only screen and (max-width: 480px) {
    .RegisterInvalid {
        padding: 32px 32px 0;
    }
}
