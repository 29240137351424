@import '../../../../../scss/_trashie.scss';

.OfferItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--trashie-ui-faded-black-30);
    background: var(--trashie-ui-crisp-white);
    overflow: hidden;
    height: 138px;
    width: calc(100% * (1/9) - 16px + 1.5px);
    cursor: pointer;

    &:hover {
        transform: scale(1.025);
    }

    &__imgContainer {
        display: flex;
        width: 100%;
        height: 96px;
        background-position: center;  
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        img {
            max-width: 100%;
            height: 100%;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px;

        &--activateButton {
            border-radius: 100px;
            border: 1px solid var(--trashie-primary-code-blue);
            padding: 6px 8px;
            width: auto;

            font-size: 11px;
            font-weight: 500;
            line-height: 150%;
            color: var(--trashie-primary-code-blue);

            &:hover {
                color: var(--trashie-primary-code-blue);
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .OfferItem {
        width: calc(100% * (1/6) - 14px);
    }
}

@media screen and (max-width: 768px) {
    .OfferItem {
        width: calc(100% * (1/3) - 6px);
    }
}
