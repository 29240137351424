@import '../../../../scss/_trashie.scss';

.main {
    border-bottom: 1px solid var(--trashie-ui-faded-black-100);
    background: var(--trashie-primary-dream-pop-pink);
    align-items: flex-start;
    z-index: 200;
    width: 100%;
    height: 72px;
    padding: 0 64px;
    display: flex;
    position: fixed;

    .container {
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        gap: 26px;
        max-width: 1312px;

        .logo {
            float: left;
            text-decoration: none;
            position: relative;

            .img {
                vertical-align: middle;
                display: inline-block;
                height: 32px;
            }
            .logoMobile {
                display: none;
            }
            .logoDesktop {
                display: block;
            }
        }

        .ctaContainer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 48px;

            .cta {
                @include ctaLink;
                font-size: 13px;
                width: auto;
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .main {
        height: 63px;
        padding: 0 20px;

        .container {
            .logo {
                .img {
                    height: 32px;
                }
                .logoMobile {
                    display: block;
                }
                .logoDesktop {
                    display: none;
                }
            }
        }
    }
}
